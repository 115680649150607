import React, {
  RefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  MapContainer,
  GeoJSON,
  TileLayer,
  ScaleControl,
  useMapEvents,
} from "react-leaflet";
import mapDataJSON from "../../data/district2.json";
import { mapColors } from "../../utils/mapColors";
import { LoadPanel } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { api } from "../../api/api";
import { ReturnWeatherDateObject, WeatherFromToDate } from "../../types";

import DateWeatherSelector from "../../components/date-weather-selector/DateWeatherSelector";
import CustomWeatherDataSelector from "./CustomWeatherDataSelector";

const Weather = () => {
  const geoJsonRef = useRef<any>(null);
  const position = { of: "#chart" };
  const weatherUrlEndpoint = "interiaweather";
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [transformedData, setTransformedData] = useState<Array<any>>([]);
  const ref: RefObject<any> = createRef();
  const [maxDataValues, setMaxDataValues] = useState<WeatherDataValues>({
    cloud_fraction_high: 0,
    cloud_fraction_low: 0,
    cloud_fraction_mid: 0,
    cloud_fraction_total: 0,
    diffuse_horizontal_insolation: 0,
    direct_horizontal_insolation: 0,
    global_horizontal_insolation: 0,
    relative_humidity: 0,
    temperature: 0,
    wind_10m_direction: 0,
    wind_10m_speed: 0,
    wind_90m_direction: 0,
    wind_90m_speed: 0,
    wind_140m_direction: 0,
    wind_140m_speed: 0,
    wind_gust: 0,
  });
  // useEffect(() => {
  //   setIsLoadingVisible(true);
  //   if (geoJsonRef.current) {
  //     console.log(geoJsonRef.current.getLayers().length);
  //     geoJsonRef.current.clearLayers();
  //     const checkDataInterval = setInterval(function () {
  //       if (
  //         geoJsonRef.current?.getLayers().length === mapDataJSON.features.length
  //       ) {
  //         console.log(geoJsonRef.current);
  //         console.log("All data has been added");
  //         clearInterval(checkDataInterval); // Clear the interval once all data has been added
  //         setIsLoadingVisible(false);
  //       } else {
  //         console.log("Not all data has been added");
  //       }
  //     }, 50);
  //     geoJsonRef.current.addData(mapDataJSON);
  //     console.log(mapDataJSON.features.length);
  //   }
  //   return () => {
  //     if (geoJsonRef.current) {
  //       geoJsonRef.current.clearLayers();
  //     }
  //   };
  // }, [selectedOption]);
  const changeVisibility = (e: any) => {
    setIsLoadingVisible(true);
    setSelectedOption(e);

    if (geoJsonRef.current) {
      setIsLoadingVisible(true);
      geoJsonRef.current.clearLayers();

      const checkDataInterval = setInterval(() => {
        const isAllDataAdded =
          geoJsonRef.current?.getLayers().length ===
          mapDataJSON.features.length;

        if (isAllDataAdded) {
          clearInterval(checkDataInterval); // Clear the interval once all data has been added
          setIsLoadingVisible(false);
        }
      }, 50);

      geoJsonRef.current.addData(mapDataJSON);
    }
  };

  const hslValues = {
    minWindSpeed: 260,
    maxWindSpeed: 0,
    windOffset: 60,
    minCloud: 0,
    maxCloud: 100,
    minCloudFraction: 100,
    maxCloudFraction: 10,
    minSunInsolation: 180,
    maxSunInsolation: 30,
    minHumiidity: 0,
    maxHumidity: 250,
    minTemperature: 250,
    maxTemperature: 0,
  };

  const values = [
    "cloud_fraction_high",
    "cloud_fraction_low",
    "cloud_fraction_mid",
    "cloud_fraction_total",
    "diffuse_horizontal_insolation",
    "direct_horizontal_insolation",
    "global_horizontal_insolation",
    "relative_humidity",
    "temperature",
    "wind_10m_direction",
    "wind_10m_speed",
    "wind_90m_direction",
    "wind_90m_speed",
    "wind_140m_direction",
    "wind_140m_speed",
    "wind_gust",
  ];
  function colorPicker(
    elementVariableValue: any,
    selectedOption: any,
    maxDataValue?: any
  ): string {
    let output;
    switch (selectedOption) {
      case 0:
      case 1:
        const maxTempValue = 40;
        const minTempValue = 20;
        const calcMaxTemperature =
          (hslValues.minTemperature - hslValues.maxTemperature) /
          (maxTempValue + minTempValue);
        const hslTemperature =
          hslValues.minTemperature -
          (elementVariableValue + minTempValue) * calcMaxTemperature;
        output = `hsl(${hslTemperature}, 100%, 50%)`;

        break;
      case 2:
        const calcMaxHumidityValues =
          (hslValues.maxHumidity - hslValues.minHumiidity) / 100;

        const hslHumidityVal =
          hslValues.minHumiidity + elementVariableValue * calcMaxHumidityValues;
        output = `hsl(${hslHumidityVal}, 100%, 50%)`;

        break;
      case 3:
      case 4:
      case 5:
      case 6:
        const caclMaxCloudFraction =
          (hslValues.minCloudFraction - hslValues.maxCloudFraction) /
          maxDataValue;
        const hslCloudFractionVal =
          hslValues.minCloudFraction -
          elementVariableValue * caclMaxCloudFraction;
        output = `hsl(0, 0%, ${hslCloudFractionVal}%)`;

        break;
      case 7:
      case 8:
      case 9:
        const caclMaxSunInsolation =
          (hslValues.minSunInsolation - hslValues.maxSunInsolation) /
          maxDataValue;
        const hslSunInsolation =
          hslValues.minSunInsolation -
          elementVariableValue * caclMaxSunInsolation;
        output = `hsl(${hslSunInsolation}, 100%, 50%)`;

        break;
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        const maxWindSpeedInMeterPerSecond = 33;
        const minWindSpeedInMeterPerSecond = 0;
        const calcMaxWindSpeed =
          (hslValues.minTemperature - hslValues.maxTemperature) /
          (maxWindSpeedInMeterPerSecond + minWindSpeedInMeterPerSecond);
        const hslWindSpeed =
          hslValues.minTemperature -
          (elementVariableValue + minWindSpeedInMeterPerSecond) *
            calcMaxWindSpeed -
          hslValues.windOffset;
        output = `hsl(${hslWindSpeed}, 100%, 50%)`;

        break;
      case 17:
        const caclMaxCloudValues =
          (hslValues.maxCloud - hslValues.minCloud) / 100;
        const hslCloudVal =
          hslValues.maxCloud +
          hslValues.minCloud -
          (hslValues.minCloud + elementVariableValue * caclMaxCloudValues);

        output = !isNaN(hslCloudVal)
          ? `hsl(360, 0%, ${hslCloudVal}%)`
          : `hsla(360, 100%, 50%, 0.5)`;
        break;

      default:
        output = "0";
        break;
    }

    return output;
  }
  const setCountyColorHSL = (element: any, countyId: any) => {
    const output = {
      color: "hsla(360, 100%, 50%, 0.5)",
      activeTile: element.temperature,
    };
    switch (selectedOption) {
      case 1:
        output.color = colorPicker(
          parseFloat(element.temperature),
          selectedOption,
          maxDataValues.temperature
        );
        output.activeTile = element.temperature;
        break;
      case 2:
        output.color = colorPicker(
          parseFloat(element.relative_humidity),
          selectedOption,
          maxDataValues.relative_humidity
        );
        output.activeTile = element.relative_humidity;
        break;
      case 3:
        output.color = colorPicker(
          parseFloat(element.cloud_fraction_low),
          selectedOption,
          maxDataValues.cloud_fraction_low
        );
        output.activeTile = element.cloud_fraction_low;
        break;
      case 4:
        output.color = colorPicker(
          parseFloat(element.cloud_fraction_mid),
          selectedOption,
          maxDataValues.cloud_fraction_mid
        );
        output.activeTile = element.cloud_fraction_mid;
        break;
      case 5:
        output.color = colorPicker(
          parseFloat(element.cloud_fraction_high),
          selectedOption,
          maxDataValues.cloud_fraction_high
        );
        output.activeTile = element.cloud_fraction_high;
        break;
      case 6:
        output.color = colorPicker(
          parseFloat(element.cloud_fraction_total),
          selectedOption,
          maxDataValues.cloud_fraction_total
        );
        output.activeTile = element.cloud_fraction_total;
        break;
      case 7:
        output.color = colorPicker(
          parseFloat(element.diffuse_horizontal_insolation),
          selectedOption,
          maxDataValues.diffuse_horizontal_insolation
        );
        output.activeTile = element.diffuse_horizontal_insolation;
        break;
      case 8:
        output.color = colorPicker(
          parseFloat(element.direct_horizontal_insolation),
          selectedOption,
          maxDataValues.direct_horizontal_insolation
        );
        output.activeTile = element.direct_horizontal_insolation;
        break;
      case 9:
        output.color = colorPicker(
          parseFloat(element.global_horizontal_insolation),
          selectedOption,
          maxDataValues.global_horizontal_insolation
        );
        output.activeTile = element.global_horizontal_insolation;
        break;
      case 10:
        output.color = colorPicker(
          parseFloat(element.wind_10m_direction),
          selectedOption,
          maxDataValues.wind_10m_direction
        );
        output.activeTile = element.wind_10m_direction;
        break;
      case 11:
        output.color = colorPicker(
          parseFloat(element.wind_90m_direction),
          selectedOption,
          maxDataValues.wind_90m_direction
        );
        output.activeTile = element.wind_90m_direction;
        break;
      case 12:
        output.color = colorPicker(
          parseFloat(element.wind_140m_direction),
          selectedOption,
          maxDataValues.wind_140m_direction
        );
        output.activeTile = element.wind_140m_direction;
        break;
      case 13:
        output.color = colorPicker(
          parseFloat(element.wind_10m_speed),
          selectedOption,
          maxDataValues.wind_10m_speed
        );
        output.activeTile = element.wind_10m_speed;
        break;
      case 14:
        output.color = colorPicker(
          parseFloat(element.wind_90m_speed),
          selectedOption,
          maxDataValues.wind_90m_speed
        );
        output.activeTile = element.wind_90m_speed;
        break;
      case 15:
        output.color = colorPicker(
          parseFloat(element.wind_140m_speed),
          selectedOption,
          maxDataValues.wind_140m_speed
        );
        output.activeTile = element.wind_140m_speed;
        break;
      case 16:
        output.color = colorPicker(
          parseFloat(element.wind_gust),
          selectedOption,
          maxDataValues.wind_gust
        );
        output.activeTile = element.wind_gust;
        break;
      default:
        output.color = colorPicker(
          parseFloat(element.temperature),
          selectedOption,
          maxDataValues.temperature
        );
        output.activeTile = element.temperature;
        break;
    }

    return output;
  };
  const changeCountryColoronMouseMove = (event: any, color: any) => {
    event.setStyle({
      fillColor: color,
    });
  };
  const popupOptions = { className: "map-custom-popup" };
  const onEachCounty = (county: any, layer: any) => {
    const countyName = county.properties.nazwa;
    const countyId = county.properties.id;
    const element = transformedData.find(
      (element) => element.place_id === county.properties.id
    );
    const popUpContent = `<p class="title">
    <b>${countyName}</b></p>
    <p>temperatura : <b>${element.temperature} &#8451;</b></p>
    <p>wilgotność : <b>${element.relative_humidity} %</b></p>
    <p>frakcja chmur niska : <b>${element.cloud_fraction_low}</b></p>
    <p>frakcja chmur średnia : <b>${element.cloud_fraction_mid}</b></p>
    <p>frakcja chmur wysoka : <b>${element.cloud_fraction_high}</b></p>
    <p>frakcja chmur całkowita : <b>${element.cloud_fraction_total}</b></p>
    <p>promieniowanie rozproszone : <b>${element.diffuse_horizontal_insolation}</b></p>
    <p>promieniowanie bezpośrednie : <b>${element.direct_horizontal_insolation}</b></p>
    <p>promieniowanie całkowite : <b>${element.global_horizontal_insolation}</b></p>
    <p>prędkość wiatru (10m) :<span style="transform: rotate(${element.wind_10m_direction}deg)">&#8593;</span> <b>${element.wind_10m_speed} m/s</b></p>
    <p>prędkość wiatru (90m) : <span style="transform: rotate(${element.wind_90m_direction}deg)">&#8593;</span> <b>${element.wind_90m_speed} m/s</b></p>
    <p>prędkość wiatru (140m) : <span style="transform: rotate(${element.wind_140m_direction}deg)">&#8593;</span> <b>${element.wind_140m_speed} m/s</b></p>
    <p>porywy wiatru : <b>${element.wind_gust} m/s</b></p>
    
   `;
    const activeLayerInfo = setCountyColorHSL(element, countyId);
    layer.bindPopup(popUpContent, popupOptions);
    layer.setStyle({
      fillColor: `${activeLayerInfo.color}`,
    });
    const activeLayer = activeLayerInfo.activeTile;
    layer.bindTooltip(`${activeLayer}`, {
      permanent: true,
      direction: "center",
      opacity: 1,
      fontSize: "0.5rem",
      className: "tooltipxs",
      color: "red",
    });
    layer.on({
      mouseover: (e: any) => {
        changeCountryColoronMouseMove(layer, "red");
      },
      mouseout: (e: any) => {
        changeCountryColoronMouseMove(layer, activeLayerInfo.color);
      },
    });
  };

  const getData = async (fromDate: string): Promise<WeatherDataValues[]> => {
    setIsLoadingVisible(true);

    const filter = {
      limit: 1,
      skip: 0,
      order: "string",
      where: {
        prediction_date: {
          $eq: fromDate,
        },
      },
      fields: {
        id: true,
        prediction_date: true,
        data_wstawienia: true,
        data_array: true,
      },
    };

    try {
      const { data } = await api.get<any>(weatherUrlEndpoint, {
        params: { filter },
      });
      if (data?.length) {
        setIsLoadingVisible(false);
        return data[0].data_array;
      } else {
        setIsLoadingVisible(false);
        notify("Brak danych w wybranym okresie czasu", "warning", 2000);

        return [];
      }
    } catch (error) {
      setIsLoadingVisible(false);
      console.error(error);
      notify("Nie udało się pobrać danych", "error", 2000);
      return [];
    }
  };

  type WeatherDataValues = {
    [key: string]: number;
  };
  const searchMaxValues = (data: WeatherDataValues[]) => {
    const maxValues: WeatherDataValues = data?.length
      ? Object.fromEntries(
          values
            .filter((key) => key in data[0])
            .map((key) => [
              key,
              Math.max(...data.map((obj) => (isNaN(obj[key]) ? 0 : obj[key]))),
            ])
        )
      : {};
    return maxValues;
  };

  function LocationMarker() {
    // const [position, setPosition] = useState(null)
    // const map = useMapEvents({
    //   click() {
    //     map.locate();
    //   },
    //   update() {
    //     console.log("change");
    //   },
    //   tileload() {
    //     console.log("tileload");
    //   },
    //   tileloadstart() {
    //     console.log("tileloadstart");
    //   },
    //   layeradd() {
    //     console.log("layeradd");
    //   },
    //   layerremove() {
    //     console.log("layerremove");
    //   },
    //   baselayerchange() {
    //     console.log("baselayerchange");
    //   },
    //   viewreset() {
    //     console.log("viewreset");
    //   },
    // });
    return null;
  }
  const getWeatherData = async (
    dates: ReturnWeatherDateObject
  ): Promise<void> => {
    const data = await getData(dates.fromDate);
    const maxValues = searchMaxValues(data);

    setMaxDataValues(maxValues);
    setTransformedData(data as any);
  };
  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>Prognozy RDN</h2>
      <DateWeatherSelector onClick={(dateObj) => getWeatherData(dateObj)} />

      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <MapContainer
            ref={ref}
            center={[52.11, 19.21]}
            zoom={7}
            minZoom={6}
            scrollWheelZoom={true}
            dragging={true}
            maxZoom={10}
          >
            {/* <ZoomControl selectedOption={selectedOption} /> */}
            <ScaleControl position="bottomleft" />
            <CustomWeatherDataSelector
              // onRadioChange={(e: any) => setSelectedOption(e)}
              onRadioChange={(e: any) => changeVisibility(e)}
            />
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom={10}
              minZoom={6}
              attribution='&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
            />
            {/* !isLoadingVisible && */}
            {transformedData?.length && (
              <GeoJSON
                ref={geoJsonRef}
                key={selectedOption}
                //   type="FeatureCollection"
                data={mapDataJSON as any}
                onEachFeature={onEachCounty}
                style={() => ({
                  color: "#555",

                  weight: 1.5,
                  fillOpacity: mapColors.map_fill_opacity,
                })}
              />
            )}
            <LocationMarker />
          </MapContainer>
        </div>
      </div>
    </>
  );
};

export default Weather;
