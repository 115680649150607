import React, { useEffect, useRef, useState, useCallback } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  Summary,
  TotalItem,
  Selection,
  Item,
} from "devextreme-react/data-grid";
import { Button, Chart, LoadPanel } from "devextreme-react";
import { Label } from "devextreme-react/form";
import { api } from "../../api/api";
import { Font, Legend, Size, Title } from "devextreme-react/bar-gauge";
import {
  AdaptiveLayout,
  CommonSeriesSettings,
  Grid,
  Crosshair,
  ZoomAndPan,
  ScrollBar,
  Series,
  ValueAxis,
  Point,
  ArgumentAxis,
  Tooltip,
  MinVisualRangeLength,
} from "devextreme-react/chart";

import { setSelectedDates as setToday } from "../../utils/DateSelectors";
import { TransformedStockData, ReturnDateObject } from "../../types";

import { onExportingDataGrid } from "../../utils/onExportingCsvFile";

import notify from "devextreme/ui/notify";

import DateTimeSelector from "../../components/date-time-selector/DateTimeSelector";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";
import { markerTemplate } from "../../utils/markerTemplates";
import DateOneDaySelector from "../../components/date-oneDay-selector/DateOneDaySelector";
import { maxValColorHex, minValColorHex } from "../../utils/constants";
import { transform } from "typescript";
import moment, { min } from "moment";
import TooltipTemplate from "./TooltipTemplate";
const TgeRdbUrl = () => {
  const position = { of: "#chart" };
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("");
  const [offestedDataVisible, setOffestedDataVisible] = useState(false);
  const stockUrlEndpoint = "tge-rdb";
  const [transformedData, setTransformedData] = useState<
    TransformedStockData[]
  >([]);
  const [transformedChartData, setTransformedChartData] = useState<any[]>([]);
  const [selectedDates, setSelectedDates] = useState<{
    fromDate: string;
    toDate: string;
    offsetedFromDate?: string;
    offsetedToDate?: string;
  }>({
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    offsetedFromDate: moment().format("YYYY-MM-DD"),
    offsetedToDate: moment().format("YYYY-MM-DD"),
  });
  const [chartDateTitle, setChartDateTitle] = useState<any>(
    moment().format("YYYY-MM-DD")
  );
  const [selectedTime, setSelectedTime] = useState<string>("00:00 - 01:00");
  const [minMaxValues, setMinMaxValues] = useState({
    offset: {
      minLastRate: 0,
      maxLastRate: 0,
      minMaxRate: 0,
      maxMaxRate: 0,
      minMinRate: 0,
      maxMinRate: 0,
    },
    default: {
      minLastRate: 0,
      maxLastRate: 0,
      minMaxRate: 0,
      maxMaxRate: 0,
      minMinRate: 0,
      maxMinRate: 0,
    },
  });

  const exportOptions = {
    fileName: `${exportFileDataName}_pl_pwm_rdn`,
  };

  type DataArr = {
    data_cet: string;
    kurs: number;
    wolumen: number;
  };
  type MyDataType = {
    data?: DataArr[] | null;
    data_cet: string;
    min_kurs: number | null;
    maks_kurs: number | null;
    ostatni_kurs: number | null;
    sumaryczny_kurs: number | null;
    _id: string;
  } & { [key: string]: any };

  const getData = async (
    fromDate: string,
    toDate: string,
    showDataField: boolean,
    offset?: boolean
  ): Promise<Array<MyDataType> | undefined> => {
    setIsLoadingVisible(true);
    const filter = !showDataField
      ? {
          order: "string",
          where: {
            data_cet: {
              $gte: fromDate,
              $lt: toDate,
            },
          },
          fields: {
            data: !!showDataField ? true : false,
            data_cet: true,
            min_kurs: true,
            maks_kurs: true,
            ostatni_kurs: true,
            sumaryczny_kurs: true,
          },
        }
      : {
          limit: 1,
          order: "string",
          where: {
            data_cet: {
              $eq: new Date(fromDate).toUTCString(),
            },
          },
        };
    try {
      const { data } = await api.get<MyDataType[]>(stockUrlEndpoint, {
        params: { filter },
      });
      console.log(data);
      if (data.length !== 0) {
        if (
          data[0] &&
          data[0].hasOwnProperty("data") &&
          data[0].data === null
        ) {
          notify(
            "Brak danych w wybranym okresie czasu " +
              convertLongDateStringToShortFormat(fromDate),
            "warning",
            2000
          );
        }

        setIsLoadingVisible(false);
        // return !!offset ? changeOffsetedObjArrnames(data[0]) : data[0];
        return data;
      } else {
        setIsLoadingVisible(false);
        notify(
          "Brak danych w wybranym okresie czasu " +
            convertLongDateStringToShortFormat(fromDate),
          "warning",
          2000
        );

        return undefined;
      }
    } catch (error) {
      setIsLoadingVisible(false);
      console.error(error);
      notify("Nie udało się pobrać danych", "error", 2000);
      return undefined;
    }
  };

  const getChartData = async (data: string): Promise<void> => {
    setChartDateTitle(moment(data).format("YYYY-MM-DD"));
    const extractedStr = moment(data).hour().toString();
    setSelectedTime(
      `${extractedStr.padStart(2, "0")}:00 - ${(+extractedStr + 1)
        .toString()
        .padStart(2, "0")}:00`
    );
    const chartDataArray = await getData(data, data, true);
    if (chartDataArray && chartDataArray.length > 0) {
      const chartData = chartDataArray[0].data || [];
      let mappedChartData = chartData.map((obj, index) => ({
        ...obj,
        data_cet: moment(obj.data_cet).milliseconds(index).valueOf(),
      }));

      setTransformedChartData(mappedChartData as []);
    }
  };

  const getStockData = async (
    selectedDates: ReturnDateObject
  ): Promise<void> => {
    setTransformedData([]);
    setOffestedDataVisible(false);
    setExportFileDataName(
      convertLongDateStringToShortFormat(selectedDates.fromDate)
    );

    if (selectedDates.offsetedFromDate && selectedDates.offsetedToDate) {
      setSelectedDates({
        fromDate: moment(selectedDates.fromDate).format("YYYY-MM-DD"),
        toDate: moment(selectedDates.toDate).format("YYYY-MM-DD"),
        offsetedFromDate: moment(selectedDates.offsetedFromDate).format(
          "YYYY-MM-DD"
        ),
        offsetedToDate: moment(selectedDates.offsetedToDate).format(
          "YYYY-MM-DD"
        ),
      });
      Promise.all([
        getData(selectedDates.fromDate, selectedDates.toDate, false),
        getData(
          selectedDates.offsetedFromDate,
          selectedDates.offsetedToDate,
          false,
          true
        ),
      ])
        .then(([data, offsetedData]) => {
          if (data !== undefined && offsetedData !== undefined) {
            setOffestedDataVisible(true);
            getChartData(selectedDates.fromDate);
            setTransformedData(mergeByHour(data, offsetedData) as any[]);
            getMinMax(data as any, false);
            getMinMax(offsetedData as any, true);
          } else if (data !== undefined && offsetedData === undefined) {
            getChartData(selectedDates.fromDate);
            getMinMax(data as any, false);
            setTransformedData(data as any[]);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setSelectedDates({
        fromDate: moment(selectedDates.fromDate).format("YYYY-MM-DD"),
        toDate: moment(selectedDates.toDate).format("YYYY-MM-DD"),
      });

      const data = await getData(
        selectedDates.fromDate,
        selectedDates.toDate,
        false
      );

      getChartData(selectedDates.fromDate);
      getMinMax(data as any, false);
      setTransformedData(data as any[]);
    }
  };
  const mergeByHour = (data: MyDataType[], offsetedData: MyDataType[]) => {
    let result = [...data];
    offsetedData.forEach((offsetedElement: any) => {
      let dataElement: any = result.find((element) => {
        return (
          moment(element.data_cet).format("HH") ===
          moment(offsetedElement.data_cet).format("HH")
        );
      });

      if (dataElement) {
        Object.keys(offsetedElement).forEach((key) => {
          dataElement[`${key}_offset`] = offsetedElement[key];
        });
      } else {
        let newElement: MyDataType = {
          data_cet: "",
          min_kurs: 0,
          maks_kurs: 0,
          ostatni_kurs: 0,
          sumaryczny_kurs: 0,
          _id: "",
        };
        Object.keys(offsetedElement).forEach((key) => {
          newElement[`${key}_offset`] = offsetedElement[key];
        });
        result.push(newElement);
      }
    });

    return result;
  };

  const onCellRenderImage = (e: any, isOffset: boolean) => {
    return (
      <Button
        className="data-grid-plus-icon"
        icon="plus"
        onClick={() => {
          if (isOffset) {
            getChartData(moment(e.data.data_cet_offset).format());
          } else {
            getChartData(moment(e.data.data_cet).format());
          }
        }}
      />
    );
  };
  useEffect(() => {
    const data = setToday({
      selectedFromDate: moment().toDate(),
    });
    getStockData(data);
  }, []);
  const onPointHoverChanged = (e: any): void => {
    if (!e.target.isHovered()) {
      e.target.hideTooltip();
    }
  };
  const onLegendClick = (e: any) => {
    e.target.isVisible() ? e.target.hide() : e.target.show();
  };

  const getMinMax = (arr: Array<MyDataType>, offset: boolean) => {
    if (!arr || arr.length === 0) return;
    type RateKey = "ostatni_kurs" | "maks_kurs" | "min_kurs";
    const keys: RateKey[] = ["ostatni_kurs", "maks_kurs", "min_kurs"];

    const filteredRates = keys.map((type) =>
      arr
        .filter((item) => item[type] !== null)
        .map((item) => item[type] as number)
    );

    const [minLastRate, minMaxRate, minMinRate] = filteredRates.map(
      (rates: number[]) => Math.min(...rates) || 0
    );

    const [maxLastRate, maxMaxRate, maxMinRate] = filteredRates.map(
      (rates: number[]) => Math.max(...rates) || 0
    );

    offset
      ? setMinMaxValues((prevState) => ({
          ...prevState,
          offset: {
            minLastRate,
            maxLastRate,
            minMaxRate,
            maxMaxRate,
            minMinRate,
            maxMinRate,
          },
        }))
      : setMinMaxValues((prevState) => ({
          ...prevState,
          default: {
            minLastRate,
            maxLastRate,
            minMaxRate,
            maxMaxRate,
            minMinRate,
            maxMinRate,
          },
        }));
  };

  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>TGE RDB</h2>
      <DateOneDaySelector onClick={(dateObj) => getStockData(dateObj)} />

      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Chart
            dataSource={transformedChartData}
            title={` ${chartDateTitle} ${selectedTime}`}
            palette="Harmony Light"
            onPointHoverChanged={onPointHoverChanged}
            onLegendClick={onLegendClick}
          >
            <Crosshair enabled={true} color="#949494" width={3} dashStyle="dot">
              <Label visible={true}>
                <Font color="#fff" size={12} />
              </Label>
            </Crosshair>
            <CommonSeriesSettings argumentField="data_cet">
              <Point visible={true} size={7} symbol="circle"></Point>
            </CommonSeriesSettings>

            <ArgumentAxis
              label={{
                format: "HH:mm",
                // displayMode: "rotate",
              }}
              argumentType="datetime"
              type="discrete"
              key={Math.random()}
            />
            <ValueAxis position="left" name="values" type="discrete">
              <Title text="Kurs (PLN/MWh)" />
            </ValueAxis>
            <ValueAxis position="right" name="wolumen" type="continuous">
              <Title text="Wolumen (MWh)" />
            </ValueAxis>
            <Series
              valueField="wolumen"
              name={`Wolumen (MWh)`}
              axis="wolumen"
              color="#009e4f"
              type={"bar"}
            />
            <Series
              valueField="kurs"
              name={`Kurs (PLN/MWh)`}
              axis="values"
              color="#c57900"
              type={"line"}
            />

            <ZoomAndPan argumentAxis="both" />
            <ScrollBar visible={true} />
            <Crosshair
              enabled={true}
              color="#949494"
              width={3}
              dashStyle="dash"
            >
              <Label visible={true}>
                <Font color="#fff" size={12} />
              </Label>
            </Crosshair>
            <Size height={500} />
            <Grid visible={true} />
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="bottom"
              markerRender={markerTemplate}
            />
            <Tooltip
              border={{ color: "#d3d3d3", width: 1 }}
              shadow={{ color: "#888", opacity: 0.5 }}
              interactive={true}
              arrowLength={10}
              enabled={true}
              shared={true}
              cornerRadius={5}
              contentRender={TooltipTemplate}
            ></Tooltip>
            <Export enabled={true} />
            <AdaptiveLayout width={450} keepLabels={true} />
          </Chart>

          <DataGrid
            keyExpr={"_id"}
            dataSource={transformedData}
            showBorders={true}
            hoverStateEnabled={true}
            repaintChangesOnly={true}
            onCellPrepared={(e: any) => {
              if (e.rowType === "data") {
                let color = "";

                switch (e.column.dataField) {
                  case "ostatni_kurs":
                    if (e.value === minMaxValues.default.maxLastRate) {
                      color = maxValColorHex;
                    } else if (e.value === minMaxValues.default.minLastRate) {
                      color = minValColorHex;
                    }
                    break;
                  case "maks_kurs":
                    if (e.value === minMaxValues.default.maxMaxRate) {
                      color = maxValColorHex;
                    } else if (e.value === minMaxValues.default.minMaxRate) {
                      color = minValColorHex;
                    }
                    break;
                  case "min_kurs":
                    if (e.value === minMaxValues.default.maxMinRate) {
                      color = maxValColorHex;
                    } else if (e.value === minMaxValues.default.minMinRate) {
                      color = minValColorHex;
                    }
                    break;
                  case "ostatni_kurs_offset":
                    if (e.value === minMaxValues.offset.maxLastRate) {
                      color = maxValColorHex;
                    } else if (e.value === minMaxValues.offset.minLastRate) {
                      color = minValColorHex;
                    }
                    break;
                  case "maks_kurs_offset":
                    if (e.value === minMaxValues.offset.maxMaxRate) {
                      color = maxValColorHex;
                    } else if (e.value === minMaxValues.offset.minMaxRate) {
                      color = minValColorHex;
                    }
                    break;
                  case "min_kurs_offset":
                    if (e.value === minMaxValues.offset.maxMinRate) {
                      color = maxValColorHex;
                    } else if (e.value === minMaxValues.offset.minMinRate) {
                      color = minValColorHex;
                    }
                    break;
                  default:
                    break;
                }

                if (color) {
                  e.cellElement.style.color = color;
                  e.cellElement.style.fontWeight = "bold";
                }
              }
            }}
            onExporting={(e) =>
              onExportingDataGrid(e, `${exportFileDataName}_ceny_niezb_rb`)
            }
          >
            <Selection mode="single" />
            <HeaderFilter visible={true} allowSearch={true} />
            <Scrolling columnRenderingMode="virtual" />
            <Export enabled={true} formats={["csv"]} />
            <Column
              caption="Czas"
              dataField="data_cet"
              dataType="date"
              sortOrder="asc"
              allowFiltering={true}
              allowSorting={true}
              width={90}
              format={(e: Date) => {
                const hour = parseInt(e.getHours().toString());
                return `${hour} - ${hour + 1}`;
              }}
            />
            <Column caption={selectedDates.fromDate}>
              <Column
                caption="Wykres"
                cellRender={(e) => onCellRenderImage(e, false)}
                alignment="center"
                width={100}
              />
              <Column
                caption="Kurs min.(PLN/MWh)"
                dataField="min_kurs"
                dataType="number"
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption="Kurs max.(PLN/MWh)"
                dataField="maks_kurs"
                dataType="number"
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption="Ostatni kurs.(PLN/MWh)"
                dataField="ostatni_kurs"
                dataType="number"
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption="Łączny wolumen (MWh)"
                dataField="sumaryczny_kurs"
                dataType="number"
                allowFiltering={true}
                allowSorting={true}
              />
            </Column>
            {!!offestedDataVisible && (
              <Column caption={selectedDates.offsetedFromDate}>
                <Column
                  caption="Wykres"
                  cellRender={(e) => onCellRenderImage(e, true)}
                  alignment="center"
                  width={100}
                />
                <Column
                  caption="Kurs min.(PLN/MWh)"
                  dataField="min_kurs_offset"
                  dataType="number"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption="Kurs max.(PLN/MWh)"
                  dataField="maks_kurs_offset"
                  dataType="number"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption="Ostatni kurs.(PLN/MWh)"
                  dataField="ostatni_kurs_offset"
                  dataType="number"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption="Łączny wolumen (MWh)"
                  dataField="sumaryczny_kurs_offset"
                  dataType="number"
                  allowFiltering={true}
                  allowSorting={true}
                />
              </Column>
            )}

            <Paging enabled={false} />
            <Pager visible={false} />
            <Summary skipEmptyValues={true} recalculateWhileEditing={true}>
              <TotalItem
                column="min_kurs"
                showInColumn="min_kurs"
                summaryType="min"
              />
              <TotalItem
                column="min_kurs"
                showInColumn="min_kurs"
                summaryType="max"
              />
              <TotalItem
                column="maks_kurs"
                showInColumn="maks_kurs"
                summaryType="min"
              />
              <TotalItem
                column="maks_kurs"
                showInColumn="maks_kurs"
                summaryType="max"
              />

              <TotalItem
                column="ostatni_kurs"
                showInColumn="ostatni_kurs"
                summaryType="min"
              />

              <TotalItem
                column="ostatni_kurs"
                showInColumn="ostatni_kurs"
                summaryType="max"
              />
              <TotalItem
                column="sumaryczny_kurs"
                showInColumn="sumaryczny_kurs"
                summaryType="sum"
                customizeText={(e: any) => {
                  return `Suma: ${e.value.toFixed(2)}`;
                }}
              />
              {/* {!!offestedDataVisible && (
                <> */}
              <TotalItem
                column="min_kurs_offset"
                showInColumn="min_kurs_offset"
                summaryType="min"
              />
              <TotalItem
                column="min_kurs_offset"
                showInColumn="min_kurs_offset"
                summaryType="max"
              />
              <TotalItem
                column="maks_kurs_offset"
                showInColumn="maks_kurs_offset"
                summaryType="min"
              />
              <TotalItem
                column="maks_kurs_offset"
                showInColumn="maks_kurs_offset"
                summaryType="max"
              />

              <TotalItem
                column="ostatni_kurs_offset"
                showInColumn="ostatni_kurs_offset"
                summaryType="min"
              />

              <TotalItem
                column="ostatni_kurs_offset"
                showInColumn="ostatni_kurs_offset"
                summaryType="max"
              />
              <TotalItem
                column="sumaryczny_kurs_offset"
                showInColumn="sumaryczny_kurs_offset"
                summaryType="sum"
                customizeText={(e: any) => {
                  return `Suma: ${e.value.toFixed(2)}`;
                }}
              />
              {/* </> */}
              {/* )} */}
            </Summary>
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TgeRdbUrl;
