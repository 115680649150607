import { SimpleItem, ButtonItem } from "devextreme-react/form";
import { useEffect, useCallback, useState } from "react";
import { Form } from "devextreme-react";
import "./DateRBSelector.css";
import { DateSelectorProps2 as DateSelectorProps } from "../../types";
import { setSelectedDates } from "../../utils/DateSelectors";
import { colCountByScreen } from "../../utils/constants";
import moment from "moment";

const DateOneDaySelector = ({ onClick }: DateSelectorProps) => {
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  // const [selectedDate, setSelectedDate] = useState(moment().format());
  const [offsetDate, setOffsetDate] = useState(selectedDate);

  const handleDateChange = useCallback(
    (value: Date, setDate: React.Dispatch<React.SetStateAction<Date>>) => {
      const newDate = moment(value).toDate();
      setDate(newDate);
    },
    []
  );

  const rangeSelector = {
    FromDate: selectedDate,
    OffsetDate: offsetDate,
  };

  const fromDateOptions = {
    max: moment().toDate(),
  };
  const offsetDateOptions = {
    max: selectedDate,
  };

  useEffect(() => {
    const data = setSelectedDates({
      selectedFromDate: selectedDate,
      selectedToDate: offsetDate,
    });
    onClick(data);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = setSelectedDates({
      selectedFromDate: selectedDate,
      selectedToDate: offsetDate,
    });
    onClick(data);
  };

  return (
    <>
      <div className={"content-block dx-card responsive-paddings"}>
        <form onSubmit={handleSubmit}>
          <Form
            className="flexEnd"
            style={{ alignItems: "flex-end !important" }}
            formData={rangeSelector}
            id={"form"}
            onFieldDataChanged={(e) => {
              if (e.dataField === "FromDate") {
                handleDateChange(e.value, setSelectedDate);
                handleDateChange(e.value, setOffsetDate);
              }
              if (e.dataField === "OffsetDate")
                handleDateChange(e.value, setOffsetDate);
            }}
            colCountByScreen={colCountByScreen}
          >
            <SimpleItem
              dataField="FromDate"
              label={{ text: "Prognoza na dzień" }}
              editorOptions={fromDateOptions}
            />
            <SimpleItem
              dataField="OffsetDate"
              label={{ text: "Przeusnięcie daty" }}
              editorOptions={offsetDateOptions}
            />
            <ButtonItem
              horizontalAlignment={"left"}
              buttonOptions={{
                text: "Wybierz",
                type: "success",
                useSubmitBehavior: true,
              }}
            />
          </Form>
        </form>
      </div>
    </>
  );
};

export default DateOneDaySelector;
