import React, { useEffect, useState } from "react";
import "devextreme/data/odata/store";

import { LoadPanel } from "devextreme-react";

import { api } from "../../api/api";

import ResponsiveBox, {
  Row,
  Col,
  Item as ResponsiveItem,
  Location,
} from "devextreme-react/responsive-box";

import { TransformedStockData, ReturnDateObject } from "../../types";

import notify from "devextreme/ui/notify";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";
import DateSelector from "../../components/date-selector/DateSelector";
import { differenceInDays } from "../../utils/calcDiffBetweenDates";
import moment from "moment";
import { mergeByProperty } from "../../utils/mergeByProperty";
import BpkdChart from "./goBilansChart";
import BpkdDataGrid from "./goBilansDataGrid";
const GoBilans = () => {
  const position = { of: "#bpkd-content" };
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [offestedDataVisible, setOffestedDataVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("");
  const [selectedDates, setSelectedDates] = useState<ReturnDateObject>({
    fromDate: "",
    toDate: "",
    offsetedFromDate: "",
    offsetedToDate: "",
  });
  const goBilansUrlEndpoint = "go-bilans";
  const [transformedData, setTransformedData] = useState<
    TransformedStockData[]
  >([]);
  type Pk5Types = {
    data_cet: string;
    data_wstawienia: string;
    _id: string;
    dane: Array<object>;
  };

  const transformPkdData = (data: any, daysOffset: number) => {
    const values = [
      "Doba",
      "Godzina",
      "Prognozowane_zapotrzebowanie_sieci",
      "Wymagana_rezerwa_mocy_OSP",
      "Nadwyzka_mocy_dostepna_dla_OSP_(7)_ + _(9)_ - _[(3)_ - _(12)]_-_(13)",
      "Nadwyzka_mocy_dostepna_dla_OSP_ponad_wymagana_rezerwe_moc_(5)_-_(4)",
      "Moc_dyspozycyjna_JW_i_magazynow_energii_swiadczacych_uslugi_bilansujace_w_ramach_RB",
      "Moc_dyspozycyjna_JW_i_magazynow_energii_swiadczacych_uslugi_bilansujace_w_ramach_RB_dostepna_dla_OSP",
      "Przewidywana_generacja_JW_i_magazynow_energii_swiadczacych_uslugi_bilansujace_w_ramach_RB_(3)_-_(9)",
      "Prognozowana_generacja_JW_i_magazynow_energii_nie_swiadczacych_uslug_bilansujacych_w_ramach_RB",
      "Prognozowana_sumaryczna_generacja_zrodel_wiatrowych",
      "Prognozowana_sumaryczna_generacja_zrodel_fotowoltaicznych",
      "Planowane_saldo_wymiany_miedzysystemowej",
      "Prognozowana_wielkosc_niedyspozycyjnosci_wynikajaca_z_ograniczen_sieciowych_wystepujacych_w_sieci_przesylowej_oraz_sieci_dystrybucyjnej_w_zakresie_dostarczania_energii_elektrycznej",
      "Prognozowana_wielkosc_niedyspozycyjnosci_wynikajacych_z_warunkow_eksploatacyjnych_JW_swiadczacych_uslugi_bilansujace_w_ramach_RB",
      "Przewidywana_generacja_zasobow_wytworczych_nieobjetych_obowiazkami_mocowymi",
      "Obowiazki_mocowe_wszystkich_jednostek_rynku_mocy",
    ];
    const transformDateFormat = (date: Date, daysOffset: number) => {
      const newDate = moment(date).add(daysOffset, "days").toString();
      // .toString();
      return newDate;
    };

    const newArray = data.map((obj: any) =>
      values.reduce((acc, key) => {
        const newKey = `${key}`;
        // if (key === "Data") {
        if (key === "Doba") {
          return {
            ...acc,
            [`${newKey}`]: transformDateFormat(obj[key], daysOffset),
            [`${newKey}_original`]: transformDateFormat(obj[key], 0),
          };
        } else {
          return { ...acc, [`${newKey}`]: obj[key] };
        }
      }, {})
    );

    const newArraychangedName = newArray.map((item: any) =>
      daysOffset
        ? Object.fromEntries(
            Object.entries(item).map(([key, value]) =>
              key === "Doba" || key === "Godzina"
                ? [key, value]
                : ["offset_" + key, value]
            )
          )
        : Object.fromEntries(
            Object.entries(item).map(([key, value]) =>
              key === "Doba" || key === "Godzina"
                ? [key, value]
                : ["orginal_" + key, value]
            )
          )
    );

    return newArraychangedName;
  };

  const getData = async (
    fromDate: string,
    toDate: string,
    daysOffset?: number
  ): Promise<Array<any> | undefined> => {
    setIsLoadingVisible(true);

    const filter = {
      limit: 1,
      // order: "string",
      where: {
        data_cet: {
          // $eq: fromDate,
          $gte: fromDate,
          // $lt: toDate,
        },
      },
    };
    try {
      setTransformedData([]);
      const { data } = await api.get<Pk5Types[]>(goBilansUrlEndpoint, {
        params: { filter },
      });
      console.log(data);
      if (data?.length) {
        setIsLoadingVisible(false);
        // const dataArr = data.reduce(
        //   (acc: any[], current) => acc.concat(current.dane),
        //   [] as any[]
        // );
        const dataArr = data[0].dane;
        console.log(dataArr);

        interface MyObject {
          Doba: Date;
          Godzina: string;
        }

        //Po poprawieniu danych w bazie do wyrzucenia
        const newDataArr: any[] = (dataArr as MyObject[]).map(
          (obj: MyObject) => {
            const godzina = Number(obj.Godzina) - 1;
            const dobaMoment = moment(obj.Doba).hour(godzina).toLocaleString();

            return {
              ...obj,
              Doba: dobaMoment,
              Godzina: godzina,
            };
          }
        );

        console.log(newDataArr);

        const pkdData_transformed = transformPkdData(
          newDataArr,
          daysOffset || 0
        );

        console.log(pkdData_transformed);

        // console.log(transformedArray);
        // const mergedArray = transformedArray.reduce((acc, current) => {
        //   return acc.concat(
        //     mergeByProperty(
        //       current.newest,
        //       current.first,
        //       // daysOffset ? "offset_Godzina" : "Godzina"
        //       "Godzina"
        //     )
        //   );
        // }, []);
        return pkdData_transformed;
      } else {
        setIsLoadingVisible(false);
        notify(
          "Brak danych w wybranym okresie czasu " +
            convertLongDateStringToShortFormat(fromDate),
          "warning",
          2000
        );

        return undefined;
      }
    } catch (error) {
      setIsLoadingVisible(false);
      notify("Nie udało się pobrać danych", "error", 2000);
      return undefined;
    }
  };

  const getStockData = async (
    selectedDates: ReturnDateObject
  ): Promise<void> => {
    setSelectedDates(selectedDates);
    setOffestedDataVisible(false);
    setExportFileDataName(
      convertLongDateStringToShortFormat(selectedDates.fromDate)
    );
    if (selectedDates.offsetedFromDate && selectedDates.offsetedToDate) {
      const dateDiff = differenceInDays(
        moment(selectedDates.fromDate).toDate(),
        moment(selectedDates.offsetedFromDate).toDate()
      );
      Promise.all([
        getData(selectedDates.fromDate, selectedDates.toDate),
        getData(
          selectedDates.offsetedFromDate,
          selectedDates.offsetedToDate,
          dateDiff
        ),
      ])
        .then(([data, offsetedData]) => {
          if (data !== undefined && offsetedData !== undefined) {
            setOffestedDataVisible(true);
            const arr = mergeByProperty(data, offsetedData, "Doba");
            const filteredData = arr
              .map((item: any) => {
                if (moment(item.Doba).isBefore(moment(selectedDates.toDate))) {
                  return item;
                }
              })
              .filter(Boolean);
            setTransformedData(filteredData as any[]);
          }
          if (data !== undefined && offsetedData === undefined) {
            const filteredData = data
              .map((item: any) => {
                if (moment(item.Doba).isBefore(moment(selectedDates.toDate))) {
                  return item;
                }
              })
              .filter(Boolean);
            setTransformedData(filteredData as any[]);
          }
        })
        .catch((error) => console.error(error));
    } else {
      const data =
        (await getData(selectedDates.fromDate, selectedDates.toDate)) || [];
      const filteredData = data
        .map((item: any) => {
          if (moment(item.Doba).isBefore(moment(selectedDates.toDate))) {
            return item;
          }
        })
        .filter(Boolean);
      setTransformedData(filteredData as any[]);
    }
  };

  function screen(width: number) {
    return width < 1400 ? "sm" : "lg";
  }

  const responsiveBoxAttributes = {
    class: "bpkd_reponsive_box",
  };
  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>PK5</h2>
      <DateSelector
        defaultOffset={2}
        maxRange={9}
        onClick={(dateObj) => getStockData(dateObj)}
      />
      <div id={"bpkd-content"} className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <ResponsiveBox
            elementAttr={responsiveBoxAttributes}
            singleColumnScreen="sm"
            screenByWidth={screen}
          >
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            {/* <Row ratio={1}></Row> */}
            {/* <Row ratio={1}></Row> */}

            <Col ratio={1}></Col>
            <Col ratio={1}></Col>
            {/* <Col ratio={1}></Col> */}

            <ResponsiveItem>
              <Location row={0} col={0} screen="lg"></Location>
              <Location row={0} col={0} screen="sm"></Location>
              <div className="content item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={
                    "Prognozowana_sumaryczna_generacja_zrodel_fotowoltaicznych"
                  }
                  variableName={
                    "Prognozowana_sumaryczna_generacja_zrodel_fotowoltaicznych"
                  }
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={
                    "Prognozowana_sumaryczna_generacja_zrodel_fotowoltaicznych"
                  }
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={0} col={1} screen="lg"></Location>
              <Location row={1} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Prognozowana_sumaryczna_generacja_zrodel_wiatrowych"}
                  variableName={
                    "Prognozowana_sumaryczna_generacja_zrodel_wiatrowych"
                  }
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={
                    "Prognozowana_sumaryczna_generacja_zrodel_wiatrowych"
                  }
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={1} col={0} screen="lg"></Location>
              <Location row={2} col={0} screen="sm"></Location>
              <div className="content item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Prognozowane_zapotrzebowanie_sieci"}
                  variableName={"Prognozowane_zapotrzebowanie_sieci"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Prognozowane_zapotrzebowanie_sieci"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={1} col={1} screen="lg"></Location>
              <Location row={3} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={
                    "Nadwyzka_mocy_dostepna_dla_OSP_ponad_wymagana_rezerwe_moc_(5)_-_(4)"
                  }
                  variableName={
                    "Nadwyzka_mocy_dostepna_dla_OSP_ponad_wymagana_rezerwe_moc_(5)_-_(4)"
                  }
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={
                    "Nadwyzka_mocy_dostepna_dla_OSP_ponad_wymagana_rezerwe_moc_(5)_-_(4)"
                  }
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={2} col={0} screen="lg"></Location>
              <Location row={4} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Planowane_saldo_wymiany_miedzysystemowej"}
                  variableName={"Planowane_saldo_wymiany_miedzysystemowej"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Planowane_saldo_wymiany_miedzysystemowej"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={2} col={1} screen="lg"></Location>
              <Location row={5} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={
                    "Przewidywana_generacja_JW_i_magazynow_energii_swiadczacych_uslugi_bilansujace_w_ramach_RB_(3)_-_(9)"
                  }
                  variableName={
                    "Przewidywana_generacja_JW_i_magazynow_energii_swiadczacych_uslugi_bilansujace_w_ramach_RB_(3)_-_(9)"
                  }
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={
                    "Przewidywana_generacja_JW_i_magazynow_energii_swiadczacych_uslugi_bilansujace_w_ramach_RB_(3)_-_(9)"
                  }
                />
              </div>
            </ResponsiveItem>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GoBilans;
