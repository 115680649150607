import moment from "moment";
import {
  SelectedTimeDates,
  ReturnDateObject,
  ReturnDateObject2,
  SelectedDates,
  SelectedRangeDates,
  ReturnWeatherDateObject,
} from "../types";

const dateFormatValue = "YYYY-MM-DD[T]HH:[00]:[00][.000+00:00]";
const dateMinuteFormatValue = "YYYY-MM-DD[T]HH:mm:[00][.000+00:00]";

const setDatetoOffsetHourFormat = (date: string): string => {
  return moment(date).add(1, "hours").startOf("hour").format();
};
const setDatetoOffsetDayFormat = (date: string): string => {
  return moment(date).add(1, "days").startOf("day").format();
};

export const setSelectedTimeDates = ({
  selectedFromDate,
  selectedToDate = selectedFromDate,
  time = "00",
}: SelectedTimeDates): ReturnDateObject => {
  const transformedSelectedFromDate = moment(selectedFromDate)
    .hour(+time)
    .startOf("hour")
    .toString();
  const transformedSelectedToDate = moment(selectedToDate)
    .hour(+time)
    .startOf("hour")
    .toString();
  const fromDate = transformedSelectedFromDate;
  const toDate =
    time !== "23"
      ? setDatetoOffsetHourFormat(transformedSelectedFromDate)
      : setDatetoOffsetDayFormat(transformedSelectedFromDate);
  const offsetedFromDate = transformedSelectedToDate;
  const offsetedToDate =
    time !== "23"
      ? setDatetoOffsetHourFormat(transformedSelectedToDate)
      : setDatetoOffsetDayFormat(transformedSelectedToDate);
  const transformedDataObj =
    fromDate === offsetedFromDate && toDate === offsetedToDate
      ? { fromDate, toDate }
      : { fromDate, toDate, offsetedFromDate, offsetedToDate };
  return transformedDataObj;
};

export const setSelectedDates = ({
  selectedFromDate,
  selectedToDate = selectedFromDate,
}: SelectedDates): ReturnDateObject => {
  const fromDate = moment(selectedFromDate).startOf("day").format();
  const toDate = moment(selectedFromDate)
    .add(1, "days")
    .startOf("day")
    .format();
  const offsetedFromDate = moment(selectedToDate).startOf("day").format();
  const offsetedToDate = moment(selectedToDate)
    .add(1, "days")
    .startOf("day")
    .format();
  const transformedDataObj =
    fromDate === offsetedFromDate && toDate === offsetedToDate
      ? { fromDate, toDate }
      : { fromDate, toDate, offsetedFromDate, offsetedToDate };
  return transformedDataObj;
};

export const setSelectedHourMinutes = (
  selectedDate: Date
): ReturnWeatherDateObject => {
  const fromDate = moment(selectedDate).startOf("minute").format();
  return {
    fromDate: fromDate,
  };
};

export const setSelectedRangeDates = ({
  selectedFromDate,
  selectedToDate,
  selectedOffsetDate,
}: SelectedRangeDates): ReturnDateObject => {
  console.log(selectedFromDate, selectedToDate, selectedOffsetDate);
  const diffInDays = moment(selectedToDate).diff(
    moment(selectedFromDate),
    "days"
  );
  console.log(diffInDays);
  const fromDate = moment(selectedFromDate).startOf("day").format();
  const toDate = moment(selectedToDate).add(1, "days").startOf("day").format();
  const offsetedFromDate = moment(selectedOffsetDate).startOf("day").format();
  const offsetedToDate = moment(selectedOffsetDate)
    .add(diffInDays, "days")
    .add(1, "days")
    .startOf("day")
    .format();
  const transformedDataObj =
    fromDate === offsetedFromDate && toDate === offsetedToDate
      ? { fromDate, toDate }
      : { fromDate, toDate, offsetedFromDate, offsetedToDate };
  console.log(transformedDataObj);
  return transformedDataObj;
};
