import moment from "moment";

const convertDate = (date: string) => moment(date).format("YYYY-MM-DD");

export const createFileNameToExport = (
  fileName: string,
  fromDate: string,
  toDate?: string
): string =>
  `${fileName}_${convertDate(fromDate)}${
    toDate ? "_" + convertDate(toDate) : ""
  }`;
