export const sevenDayTimeSpan = 7 * 24 * 60 * 60 * 1000;
export const maxWeatherDaySpan = 24 * 24 * 60 * 60 * 1000;
export const isoDateTimeFormat = "T00:00:00.0000000";
export const cookieExpiredTimeSeconds = 86400;
export const accessTokenCookieName = "kenoacc";
export const refreshTokenCookieName = "kenoref";
export const defaultDateOffsetInDays = 7;
export const colCountByScreen = {
  xs: 2,
  sm: 2,
  md: 3,
  lg: 4,
};
export const maxValColorHex = "#FF0000";
export const minValColorHex = "#00cc00";
