import React, { useEffect, useRef, useState } from "react";
import "devextreme/data/odata/store";
import DataGrid, { Export, StateStoring } from "devextreme-react/data-grid";
import "./stock.css";

import { Chart, LoadPanel, PivotGrid } from "devextreme-react";
import { Label } from "devextreme-react/form";
import { api } from "../../api/api";
import { Font, Legend, Size } from "devextreme-react/bar-gauge";
import {
  ArgumentAxis,
  AdaptiveLayout,
  CommonSeriesSettings,
  Point,
  Grid,
  Crosshair,
  ZoomAndPan,
  ScrollBar,
  Series,
  Tooltip,
} from "devextreme-react/chart";
import { FieldChooser, FieldPanel } from "devextreme-react/pivot-grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source"; // PivotGridDataSourceField,
import { TransformedStockData, ReturnDateObject } from "../../types";
import DateSelector from "../../components/date-selector/DateSelector";
import { onExportingPivotGrid } from "../../utils/onExportingCsvFile";
import { montelStockDataSourceModel } from "../../utils/pivotGridDataSources";
import notify from "devextreme/ui/notify";
import { createFileNameToExport } from "../../utils/createFileNameToExportCsv";
import moment from "moment";
import { differenceInDays } from "../../utils/calcDiffBetweenDates";
import TooltipTemplate from "./TooltipTemplate";
export default function Stock() {
  const position = { of: "#chart" };
  const chartRef = useRef<any>(null);
  const [dateDiff, setdateDiff] = useState<number>(0);
  const pivotGridRef = useRef<any>(null);
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("");
  const stockUrlEndpoint = "montel";
  const [transformedData, setTransformedData] = useState<
    TransformedStockData[] | null
  >([]);

  useEffect(() => {
    if (pivotGridRef?.current && chartRef?.current) {
      const { instance: pivotGridInstance } = pivotGridRef.current;
      const { instance: chartInstance } = chartRef.current;
      pivotGridInstance.bindChart(chartInstance, {
        dataFieldsDisplayMode: "splitPanes",
        alternateDataFields: true,
        putDataFieldsInto: "series",
      });
    }
  }, [pivotGridRef, chartRef]);
  const getData = async (
    fromDate: string,
    toDate: string
  ): Promise<Array<any> | undefined | null> => {
    setIsLoadingVisible(true);

    const filter = {
      order: "string",
      where: {
        Date: {
          $gte: fromDate,
          $lt: toDate,
        },
      },
    };
    try {
      const { data } = await api.get<any[]>(stockUrlEndpoint, {
        params: { filter },
      });

      if (data?.length) {
        const newArr = data.flatMap(
          ({ Date: date, exchange, TimeSpans }: any) => {
            return TimeSpans.map(({ TimeSpan, Value }: any) => ({
              date: date,
              originalDate: date,
              timeSpan: TimeSpan,
              value: Value,
              name: exchange,
            }));
          }
        );

        setIsLoadingVisible(false);
        return newArr;
      }

      setIsLoadingVisible(false);
      notify("Brak danych w wybranym okresie czasu", "warning", 2000);

      return null;
    } catch (error) {
      setIsLoadingVisible(false);
      notify("Nie udało się pobrać danych", "error", 2000);
      return null;
    }
  };

  const generateNewArrayWithDateNames = (
    data: Array<any>,
    fromDate: string,
    toDate: string
  ) => {
    const formatDate = "YYYY-MM-DD";
    const newDateName = `${moment(fromDate).format(formatDate)} - ${moment(
      toDate
    )
      .subtract(1, "day")
      .format(formatDate)}`;
    return data?.map((dateObj: any) => {
      return {
        ...dateObj,
        name: `${dateObj.name} - ${newDateName} `,
        off: false,
      };
    });
  };
  // const changeStockName = (data: Array<any>, name: string) => {
  //   return (
  //     data?.map((dateObj: any) => {
  //       let currentMoment = moment(dateObj.date);
  //       let offsetedDate = currentMoment
  //         .add(dateDiff, "days")
  //         .format("YYYY-MM-DD");
  //       return {
  //         ...dateObj,
  //         originalDate: offsetedDate,
  //         name: `${dateObj.name} - ${dateDiff} dni `,
  //       };
  //     }) || []
  //   );
  // };
  const getStockData = async (
    selectedDates: ReturnDateObject
  ): Promise<void> => {
    setExportFileDataName(
      createFileNameToExport(
        "Montel",
        selectedDates.fromDate,
        selectedDates.toDate
      )
    );

    if (selectedDates.offsetedFromDate && selectedDates.offsetedToDate) {
      Promise.all([
        getData(selectedDates.fromDate, selectedDates.toDate),
        getData(selectedDates.offsetedFromDate, selectedDates.offsetedToDate),
      ])
        .then(([data, offsetedData]) => {
          if (
            data !== undefined &&
            data !== null &&
            offsetedData !== undefined &&
            offsetedData !== null
          ) {
            const dateDiff = differenceInDays(
              moment(selectedDates.fromDate).toDate(),
              moment(selectedDates.offsetedFromDate).toDate()
            );
            setdateDiff(dateDiff);

            let newDates: any[] =
              offsetedData?.map((dateObj: any) => {
                let currentMoment = moment(dateObj.date);
                let offsetedDate = currentMoment
                  .add(dateDiff, "days")
                  .format("YYYY-MM-DD[T]00:00:00.000[Z]");
                return {
                  ...dateObj,
                  originalDate: offsetedDate,
                };
              }) || [];

            const changedDataNameArr = generateNewArrayWithDateNames(
              data,
              selectedDates.fromDate,
              selectedDates.toDate
            );
            const changedoffsetedDataNameArr = generateNewArrayWithDateNames(
              newDates,
              selectedDates.offsetedFromDate as string,
              selectedDates.offsetedToDate as string
            );

            const newDataArr = changedDataNameArr?.concat(
              changedoffsetedDataNameArr
            );

            console.log(newDataArr);
            setTransformedData(newDataArr as any[]);
          } else if (
            data !== undefined &&
            data !== null &&
            offsetedData === undefined &&
            offsetedData === null
          ) {
            const changedDataNameArr = generateNewArrayWithDateNames(
              data,
              selectedDates.fromDate,
              selectedDates.toDate
            );

            setTransformedData(changedDataNameArr as any[]);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setdateDiff(0);
      const data = await getData(selectedDates.fromDate, selectedDates.toDate);
      if (data) {
        const changedDataNameArr = generateNewArrayWithDateNames(
          data,
          selectedDates.fromDate,
          selectedDates.toDate
        );
        setTransformedData(changedDataNameArr as any[]);
      }
    }
  };

  const pivotDataSource = new PivotGridDataSource({
    fields: montelStockDataSourceModel as [],
    store: transformedData || [],
  });
  const onPointHoverChanged = (e: any): void => {
    if (!e.target.isHovered()) {
      e.target.hideTooltip();
    }
  };

  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>Rynek giełdy</h2>
      <DateSelector onClick={(dateObj) => getStockData(dateObj)} />

      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Chart
            export={{
              enabled: true,
              fileName: `${exportFileDataName}`,
            }}
            onFileSaving={(e) => {
              console.log(e);
            }}
            ref={chartRef}
            palette="Harmony Light"
            // customizePoint={(erg) => {
            //   console.log(erg);
            //   return erg;
            // }}
            // onPointHoverChanged={onPointHoverChanged}
            tooltip={{
              enabled: true,
              customizeTooltip(arg: any) {
                return {
                  text: `${arg.seriesName}<br/>${parseFloat(
                    arg.valueText
                  ).toFixed(2)} &#8364;`,
                };
              },
            }}
          >
            <ZoomAndPan argumentAxis="both" />
            <ScrollBar visible={true} />
            <ArgumentAxis
              valueMarginsEnabled={false}
              discreteAxisDivisionMode="crossLabels"
            >
              <Grid visible={true} />
            </ArgumentAxis>
            <Crosshair enabled={true} color="#949494" width={3} dashStyle="dot">
              <Label visible={true}>
                <Font color="#fff" size={12} />
              </Label>
            </Crosshair>
            <Size height={500} />
            <Grid visible={true} />
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="bottom"
              customizeItems={(items: any) => {
                console.log(items);
                return items;
              }}
            />
            <Export enabled={true} />

            <CommonSeriesSettings type={`spline`}>
              <Point visible={false} hoverMode="allArgumentPoints"></Point>
            </CommonSeriesSettings>
            <AdaptiveLayout width={450} keepLabels={true} />
            {/* <Tooltip
              border={{ color: "#d3d3d3", width: 1 }}
              shadow={{ color: "#888", opacity: 0.5 }}
              interactive={true}
              arrowLength={10}
              enabled={true}
              shared={true}
              cornerRadius={5}
              contentRender={(e) => TooltipTemplate(e, dateDiff)}
            /> */}
          </Chart>
          <PivotGrid
            onExporting={(e) =>
              onExportingPivotGrid(e, `${exportFileDataName}`)
            }
            id="pivotgrid"
            dataSource={pivotDataSource}
            allowExpandAll={true}
            allowSorting={false}
            allowSortingBySummary={false}
            allowFiltering={true}
            showBorders={true}
            showColumnTotals={false}
            showColumnGrandTotals={false}
            showRowTotals={false}
            showRowGrandTotals={false}
            ref={pivotGridRef}
          >
            <StateStoring
              enabled={false}
              type="localStorage"
              storageKey="dx-widget-gallery-pivotgrid-storing"
            />
            <FieldPanel visible={true} showFilterFields={false} />
            <FieldChooser enabled={true} height={400} />
            <Export
              enabled={true}
              allowExportSelectedData={true}
              formats={["csv"]}
            />
          </PivotGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
