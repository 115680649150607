export const pregroupedData = [
  {
    Category: "Ogólne",
    Products: [
      {
        ID: 1,
        Name: "Temperatura",
      },
      {
        ID: 2,
        Name: "Wilgotność",
      },
    ],
  },
  {
    Category: "Frakcja chmur",
    Products: [
      {
        ID: 3,
        Name: "Niska",
      },
      {
        ID: 4,
        Name: "Średnia",
      },
      {
        ID: 5,
        Name: "Wysoka",
      },
      {
        ID: 6,
        Name: "Całkowita",
      },
    ],
  },
  {
    Category: "Nasłonecznienie poziome",
    Products: [
      {
        ID: 7,
        Name: "Rozproszone",
      },
      {
        ID: 8,
        Name: "Bezpośrednie",
      },
      {
        ID: 9,
        Name: "Globalne",
      },
    ],
  },
  {
    Category: "Kierunek wiatru ",
    Products: [
      {
        ID: 10,
        Name: "wysokość 10m",
      },
      {
        ID: 11,
        Name: "wysokość 90m",
      },
      {
        ID: 12,
        Name: "wysokość 140m",
      },
    ],
  },
  {
    Category: "Prędkość wiatru",
    Products: [
      {
        ID: 13,
        Name: "wysokość 10m",
      },
      {
        ID: 14,
        Name: "wysokość 90m",
      },
      {
        ID: 15,
        Name: "wysokość 140m",
      },
      {
        ID: 16,
        Name: "porywy wiatru",
      },
    ],
  },
];
