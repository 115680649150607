import React, { useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  PatternRule,
  AsyncRule,
  CompareRule,
  EmailRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import { passwordTextRegex } from "../../utils/regexTest";
import notify from "devextreme/ui/notify";
import { ValidationType, validationCallbackType } from "../../types";
import { changePassword } from "../../api/auth";
import {
  TextBox,
  ValidationSummary,
  Validator,
  Button,
} from "devextreme-react";

export default function ChangePasswordForm({
  userId,
}: {
  userId: string | undefined;
}) {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const formData = useRef({
    password: "",
    newPassword: "",
    confirmedPassword: "",
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const { password } = formData.current;
    const { newPassword } = formData.current;
    if (userId) {
      setLoading(true);
      console.log(password, newPassword, userId);

      const result = await changePassword(password, newPassword, userId);
      setLoading(false);

      if (result.isOk) {
        navigate("/login");
      } else {
        notify(result.message, "error", 2000);
      }
    }
  };

  const confirmNewPasswordValidation: validationCallbackType = ({
    value,
  }: {
    value: string | number;
  }) => {
    const isDisabled = value === formData.current.password;
    setIsDisabled(isDisabled);
    return !isDisabled;
  };
  const confirmPasswordValidation: validationCallbackType = ({
    value,
  }: {
    value: string | number;
  }) => {
    const isDisabled = value !== formData.current.password;
    setIsDisabled(isDisabled);
    return !isDisabled;
  };

  const passwordLabel = { "aria-label": "Password" };
  return (
    <form onSubmit={onSubmit}>
      <div className="dx-fieldset">
        <div className="dx-fieldset-header">Zmiana hasła</div>
        <div className="dx-field">
          <div className="dx-field-label">Hasło</div>
          <div className="dx-field-value">
            <TextBox
              mode={"password"}
              value={"password"}
              inputAttr={passwordLabel}
              onValueChange={(e) => console.log(e)}
            >
              <Validator>
                <RequiredRule message="Hasło jest wymagane" />
                <PatternRule
                  pattern={passwordTextRegex}
                  message="Hasło nie spełnia wymagań"
                />
                <CustomRule
                  message={"Hasła nie pasują"}
                  validationCallback={confirmPasswordValidation}
                />
              </Validator>
            </TextBox>
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Powtórz hasło</div>
          <div className="dx-field-value">
            <TextBox
              mode={"password"}
              value={"password"}
              inputAttr={passwordLabel}
              onValueChanged={(e) => console.log(e)}
            >
              <Validator>
                <RequiredRule message="Hasło jest wymagane" />
                <PatternRule
                  pattern={passwordTextRegex}
                  message="Hasło nie spełnia wymagań"
                />
                <CustomRule
                  message={"Hasła nie pasują"}
                  validationCallback={confirmPasswordValidation}
                />
              </Validator>
            </TextBox>
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Nowe hasło</div>
          <div className="dx-field-value">
            <TextBox
              mode={"password"}
              value={"password"}
              inputAttr={passwordLabel}
              onValueChanged={(e) => console.log(e)}
            >
              <Validator>
                <RequiredRule message="Confirm Password is required" />
                <CompareRule
                  message="Password and Confirm Password do not match"
                  // comparisonTarget={nulln}
                />
              </Validator>
            </TextBox>
          </div>
        </div>
      </div>
      {/* <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Hasło jest wymagane" />
          <PatternRule
            pattern={passwordTextRegex}
            message="Hasło nie spełnia wymagań"
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"confirmedPassword"}
          editorType={"dxTextBox"}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <Validator>
            <RequiredRule message="Hasło jest wymagane" />
            <PatternRule
              pattern={passwordTextRegex}
              message="Hasło nie spełnia wymagań"
            />
            <CustomRule
              message={"Hasła nie pasują"}
              validationCallback={confirmPasswordValidation}
            />
          </Validator>
          <Label visible={false} />
        </Item>
        <Item
          dataField={"newPassword"}
          editorType={"dxTextBox"}
          editorOptions={newPasswordEditorOptions}
        >
          <RequiredRule message="Hasło jest wymaagne" />
          <PatternRule
            pattern={passwordTextRegex}
            message="Hasło nie spełnia wymagań"
          />
          <CustomRule
            message={"Nowe hasło musi być inne od starego"}
            validationCallback={confirmNewPasswordValidation}
          />
          <Label visible={false} />
        </Item>
        <ValidationSummary id="summary" />
        <ButtonItem
          horizontalAlignment={"left"}
          itemType="button"
          buttonOptions={{
            disabled: loading,
            useSubmitBehavior: true,
            text: "Zmień hasło",
            type: "success",
            // onClick: () => {
            //   changePassword();
            // },
          }}
        />
      </Form>
      <div className="dx-fieldset">
        <ValidationSummary id="summary" />
      </div> */}
      <div className="dx-fieldset">
        <ValidationSummary id="summary" />
        <Button
          disabled={loading}
          width="100px"
          style={{ margin: "20px auto" }}
          id="button"
          text="Wyślij"
          type="success"
          useSubmitBehavior={true}
        />
      </div>
    </form>
  );
}

const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Hasło",
  mode: "password",
};
const confirmedPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Potwierdź  hasło",
  mode: "password",
};
const newPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Nowe hasło",
  mode: "password",
};
