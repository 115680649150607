export const navigation = [
  {
    text: "Strona główna",
    path: "/home",
    icon: "home",
  },
  {
    text: "Giełda",
    path: "/gielda",
    icon: "chart",
  },
  {
    text: "Bossa",
    path: "/bossa",
    icon: "chart",
  },
  // {
  //   text: "Historia",
  //   path: "/history",
  //   icon: "chart",
  // },
  {
    text: "Prognozy",
    path: "/weather",
    icon: "chart",
  },
  {
    text: "PK5",
    path: "/go-bilans",
    icon: "chart",
  },
  {
    text: "Ceny Niezb Rb",
    path: "/pse-niezb-rb",
    icon: "chart",
  },
  {
    text: "PKD",
    path: "/pkd",
    icon: "chart",
  },
  {
    text: "Ceny TGE RDB",
    path: "/tge-rdb",
    icon: "chart",
  },
  {
    text: "Ceny Pl Pwm Rdb",
    path: "/pl-pwm-rdb",
    icon: "chart",
  },
  {
    text: "Ceny Pl Pwm Rdn",
    path: "/pl-pwm-rdn",
    icon: "chart",
  },
  {
    text: "BPKD",
    path: "/bpkd",
    icon: "chart",
  },
  {
    text: "Rynek dnia następnego",
    icon: "rdn",
    items: [
      {
        text: "Raporty PSE",
        icon: "pse",
        items: [
          {
            text: "PK5",
            path: "/pk5",
          },
          {
            text: "Wymiana Międzysystemowa RDN",
            path: "/wymiana-rdn",
          },
        ],
      },
      {
        text: "Prognozy RDN",
        icon: "rdn",
        items: [
          {
            text: "Prognozy RDN",
            path: "/prognozy-rdn",
          },
          {
            text: "Arbitraż DAMC - Fix1",
            path: "/fix1",
          },
        ],
      },
      {
        text: "Market Coupling",
        icon: "mc",
        items: [
          {
            text: "Ceny Market Coupling",
            path: "/ceny-market-coupling",
          },
        ],
      },
    ],
  },
  {
    text: "Rynek dnia bieżącego",
    icon: "rdb",
    items: [
      {
        text: "Tabele",
        path: "/tabele",
      },
      {
        text: "Wykresy",
        path: "/wykresy",
      },
      {
        text: "Wymiana Międzysystemowa RDB",
        path: "/wymiana-rdb",
      },
    ],
  },
  {
    text: "Rynek bilansując",
    icon: "rb",
    items: [
      // {
      //   text: "BPKD",
      //   path: "/bpkd",
      // },
      {
        text: "Prognozy RB",
        path: "/prognozy-rb",
      },
      {
        text: "Stan Zakontraktowania",
        path: "/stan-zakontraktowania",
      },
    ],
  },
  {
    text: "Rynek terminowy",
    icon: "rt",
    items: [
      {
        text: "LOP",
        path: "/lop",
      },
      {
        text: "TGE Wykonanie Terminowe",
        path: "/tge-wykonanie-terminowe",
      },
      {
        text: "TGE Kontrakty Terminowe",
        path: "/tge-kontrakty-terminowe",
      },
    ],
  },
];
