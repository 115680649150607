import { DropDownBox, RadioGroup, SelectBox } from "devextreme-react";
import React, { ReactNode } from "react";
import "./customWeatherDataSelector.css";
import CustomStore from "devextreme/data/custom_store";
import ArrayStore from "devextreme/data/array_store";
import { pregroupedData } from "./data.js";
import DataSource from "devextreme/data/data_source";

const CustomWeatherDataSelector = (props: any) => {
  const onChange = (e: any) => {
    props.onRadioChange(e);
  };
  const fromPregroupedData = new DataSource({
    store: {
      type: "array",
      data: pregroupedData,
      key: "ID",
    },
    map(item: any) {
      item.key = item.Category;
      item.items = item.Products;
      return item;
    },
  });

  return (
    <div className="form content-block dx-card weather-container">
      <div className="dx-fieldset">
        <div className="dx-field">
          <div className="dx-field-value" style={{ width: "100%" }}>
            <SelectBox
              dataSource={fromPregroupedData}
              valueExpr="ID"
              searchEnabled={true}
              onValueChanged={(e) => onChange(e.value)}
              grouped={true}
              displayExpr="Name"
              defaultValue={1}
            />
            {/* <RadioGroup
              items={priorities}
              defaultValue={priorities[0]}
              onValueChanged={onChange}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomWeatherDataSelector;
