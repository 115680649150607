import {
  HomePage,
  StockPage,
  ProfilePage,
  HistoryPage,
  ForecastRDNPage,
  BossaPage,
  Weather,
  PseNiezbRb,
  PlPwmRdb,
  PlPwmRdn,
  TgeRdbUrl,
  Bpkd,
  Pkd,
  GoBilans,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  {
    path: "/gielda",
    element: StockPage,
  },
  {
    path: "/profile",
    element: ProfilePage,
  },
  {
    path: "/home",
    element: HomePage,
  },
  {
    path: "/bossa",
    element: BossaPage,
  },
  {
    path: "/history",
    element: HistoryPage,
  },
  {
    path: "/go-bilans",
    element: GoBilans,
  },
  {
    path: "/prognozy-rdn",
    element: ForecastRDNPage,
  },
  {
    path: "/weather",
    element: Weather,
  },
  {
    path: "/pse-niezb-rb",
    element: PseNiezbRb,
  },
  {
    path: "/pl-pwm-rdb",
    element: PlPwmRdb,
  },
  {
    path: "/pl-pwm-rdn",
    element: PlPwmRdn,
  },
  {
    path: "/tge-rdb",
    element: TgeRdbUrl,
  },
  {
    path: "/bpkd",
    element: Bpkd,
  },
  {
    path: "/pkd",
    element: Pkd,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
