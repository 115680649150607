import { SimpleItem, ButtonItem, Item } from "devextreme-react/form";
import { useEffect, useState } from "react";
import { DateBox, DateRangeBox, Form } from "devextreme-react";
import "./DateWeatherSelector.css";
import { DateWeatherSelectorProps } from "../../types";
import { colCountByScreen } from "../../utils/constants";
import { setSelectedHourMinutes } from "../../utils/DateSelectors";
import moment from "moment";
import React from "react";

const DateWeatherSelector = ({ onClick }: DateWeatherSelectorProps) => {
  const [selectedDate, setSelectedDate] = useState(
    moment().startOf("hour").toDate()
  );

  const handleFromChange = (value: Date) => {
    const minutes = value.getMinutes();
    if (minutes < 7.5) {
      value.setMinutes(0);
    } else if (minutes < 22.5) {
      value.setMinutes(15);
    } else if (minutes < 37.5) {
      value.setMinutes(30);
    } else {
      value.setMinutes(45);
    }
    setSelectedDate(moment(value).toDate());
  };

  const rangeSelector = {
    FromDate: selectedDate,
  };

  const submitData = () => {
    const data = setSelectedHourMinutes(selectedDate);
    onClick(data);
  };

  useEffect(() => {
    submitData();
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitData();
  };
  const formatInterval = (e: any) => {
    e.component._strategy._timeView._minuteBox.option("step", 15);
  };
  return (
    <>
      <div className={"content-block dx-card responsive-paddings"}>
        <form onSubmit={handleSubmit}>
          <Form
            className="flexEnd"
            style={{ alignItems: "flex-end !important" }}
            formData={rangeSelector}
            id={"form"}
            onFieldDataChanged={(e) => {
              e.dataField === "FromDate" && handleFromChange(e.value);
            }}
            colCountByScreen={colCountByScreen}
          >
            <SimpleItem
              dataField="FromDate"
              editorType="dxDateBox"
              editorOptions={{
                type: "datetime",
                onOpened: (e: any) => formatInterval(e),
              }}
              label={{ text: "Prognoza z dnia" }}
            />
            ;
            <ButtonItem
              horizontalAlignment={"left"}
              buttonOptions={{
                text: "Wybierz",
                type: "success",
                useSubmitBehavior: true,
              }}
            />
          </Form>
        </form>
      </div>
    </>
  );
};

export default DateWeatherSelector;
