import React, { useEffect, useRef, useState } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Export,
  Format,
  HeaderFilter,
  Pager,
  Scrolling,
} from "devextreme-react/data-grid";

import { Chart, LoadPanel } from "devextreme-react";
import { Label } from "devextreme-react/form";
import { api } from "../../api/api";
import { Font, Legend, Size, Title } from "devextreme-react/bar-gauge";
import { setSelectedDates as setToday } from "../../utils/DateSelectors";
import {
  AdaptiveLayout,
  CommonSeriesSettings,
  Grid,
  Crosshair,
  ZoomAndPan,
  ScrollBar,
  Series,
  ValueAxis,
  Point,
  Tooltip,
  Color,
} from "devextreme-react/chart";

import {
  TransformedStockData,
  PseNiezbRbFromToDate,
  PseNiezbRbInterface,
  PlPwmRdbTypes,
  PlPwmRdbData,
} from "../../types";

import { onExportingDataGrid } from "../../utils/onExportingCsvFile";
import notify from "devextreme/ui/notify";
import DateOneDaySelector from "../../components/date-oneDay-selector/DateOneDaySelector";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";
import { markerTemplate } from "../../utils/markerTemplates";
import TooltipTemplate from "./TooltipTemplate";
import moment from "moment";

const PlPwmRdb = () => {
  const displayedDateFormat = "DD-MM-YYYY";
  const position = { of: "#chart" };
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("");
  const [selectedDates, setSelectedDates] = useState<{
    fromDate: string;
    toDate: string;
    offsetedFromDate?: string;
    offsetedToDate?: string;
  }>({
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    offsetedFromDate: moment().format("YYYY-MM-DD"),
    offsetedToDate: moment().format("YYYY-MM-DD"),
  });
  const [offestedDataVisible, setOffestedDataVisible] = useState(false);
  const stockUrlEndpoint = "pwm-rdb";
  const [transformedData, setTransformedData] = useState<
    TransformedStockData[]
  >([]);
  const pagetitle = "Program wymiany międzynarodowej RDB";
  const changeOffsetedObjArrnames = (arr: Array<PlPwmRdbData>) => {
    const newArray = arr.map((obj: PlPwmRdbData) =>
      Object.fromEntries(
        Object.entries(obj).map(([key, val]: [string, any]) =>
          key === "Godzina" ? [key, val] : [`${key}_off`, val]
        )
      )
    );
    return newArray;
  };
  const dataGridRef = useRef<any>(null);

  const getData = async (
    fromDate: string,
    toDate: string,
    offset?: boolean
  ): Promise<Array<any> | undefined> => {
    setIsLoadingVisible(true);

    const filter = {
      limit: 1,
      order: "string",
      where: {
        data_cet: {
          $gte: fromDate,
          $lt: toDate,
        },
      },
    };
    try {
      setTransformedData([]);
      const { data } = await api.get<PlPwmRdbTypes[]>(stockUrlEndpoint, {
        params: { filter },
      });
      console.log(data);
      if (data?.length) {
        setIsLoadingVisible(false);
        return !!offset
          ? changeOffsetedObjArrnames(data[0].dane)
          : data[0].dane;
      } else {
        setIsLoadingVisible(false);
        notify(
          "Brak danych w wybranym okresie czasu " +
            moment(fromDate).format(displayedDateFormat),
          "warning",
          2000
        );

        return undefined;
      }
    } catch (error) {
      setIsLoadingVisible(false);
      console.error(error);
      notify("Nie udało się pobrać danych", "error", 2000);
      return undefined;
    }
  };

  const getStockData = async (
    selectedDates: PseNiezbRbFromToDate
  ): Promise<void> => {
    setOffestedDataVisible(false);
    setExportFileDataName(
      convertLongDateStringToShortFormat(selectedDates.fromDate)
    );

    if (selectedDates.offsetedFromDate && selectedDates.offsetedToDate) {
      setSelectedDates({
        fromDate: moment(selectedDates.fromDate).format("YYYY-MM-DD"),
        toDate: moment(selectedDates.toDate).format("YYYY-MM-DD"),
        offsetedFromDate: moment(selectedDates.offsetedFromDate).format(
          "YYYY-MM-DD"
        ),
        offsetedToDate: moment(selectedDates.offsetedToDate).format(
          "YYYY-MM-DD"
        ),
      });
      Promise.all([
        getData(selectedDates.fromDate, selectedDates.toDate),
        getData(
          selectedDates.offsetedFromDate,
          selectedDates.offsetedToDate,
          true
        ),
      ])
        .then(([data, offsetedData]) => {
          if (data !== undefined && offsetedData !== undefined) {
            setOffestedDataVisible(true);
            const concatedArr = [...data, ...offsetedData].reduce(
              (acc, curr) => {
                const findingIndex = acc.findIndex(
                  (el: { Godzina: any }) => el.Godzina === curr.Godzina
                );
                if (findingIndex !== -1) {
                  acc[findingIndex] = {
                    ...acc[findingIndex],
                    ...curr,
                  };
                } else {
                  acc.push({ ...curr });
                }
                return acc;
              },
              []
            );
            setTransformedData(concatedArr as any[]);
          } else if (data !== undefined && offsetedData === undefined) {
            setTransformedData(data as any[]);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setSelectedDates({
        fromDate: moment(selectedDates.fromDate).format("YYYY-MM-DD"),
        toDate: moment(selectedDates.toDate).format("YYYY-MM-DD"),
      });
      const data = await getData(selectedDates.fromDate, selectedDates.toDate);
      setTransformedData(data as any[]);
    }
  };

  useEffect(() => {
    const data = setToday({
      selectedFromDate: new Date(),
    });
    getStockData(data);
  }, []);

  const onPointHoverChanged = (e: any): void => {
    if (!e.target.isHovered()) {
      e.target.hideTooltip();
    }
  };
  const onLegendClick = (e: any) => {
    e.target.isVisible() ? e.target.hide() : e.target.show();
  };

  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>{pagetitle}</h2>
      <DateOneDaySelector onClick={(dateObj) => getStockData(dateObj)} />

      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Chart
            // key={Math.random()}
            dataSource={transformedData}
            palette="Harmony Light"
            onPointHoverChanged={onPointHoverChanged}
            onLegendClick={onLegendClick}
            export={{
              enabled: true,
              fileName: `${exportFileDataName}_${pagetitle}`,
            }}
          >
            <CommonSeriesSettings type={`spline`} argumentField="Godzina">
              <Point visible={false} size={3} symbol="circle"></Point>
            </CommonSeriesSettings>

            <ValueAxis position="left" name="values">
              <Title text="MW" />
            </ValueAxis>
            <Series
              valueField="Czechy_EXP"
              name={`Czechy export ${selectedDates.fromDate}`}
              axis="values"
              color="#F44336"
              type={"spline"}
            />
            {offestedDataVisible && (
              <Series
                valueField="Czechy_EXP_off"
                name={`Czechy export ${selectedDates.offsetedFromDate}`}
                axis="values"
                color="#85251e"
                dashStyle={"dash"}
                type={"spline"}
              />
            )}
            <Series
              valueField="Czechy_IMP"
              name={`Czechy import ${selectedDates.fromDate}`}
              axis="values"
              color="#F44336"
              type={"spline"}
            />
            {offestedDataVisible && (
              <Series
                valueField="Czechy_IMP_off"
                name={`Czechy export ${selectedDates.offsetedFromDate}`}
                axis="values"
                color="#85251e"
                dashStyle={"dash"}
                type={"spline"}
              />
            )}
            <Series
              valueField="Slowacja_EXP"
              name={`Słowcja export ${selectedDates.fromDate}`}
              axis="values"
              color="#9C27B0"
              dashStyle={"dash"}
              type={"spline"}
            />
            {offestedDataVisible && (
              <Series
                valueField="Slowacja_EXP_off"
                name={`Słowcja export ${selectedDates.offsetedFromDate}`}
                axis="values"
                color="#5d006d"
                dashStyle={"dash"}
                type={"spline"}
              />
            )}
            <Series
              valueField="Slowacja_IMP"
              name={`Słowacja import ${selectedDates.fromDate}`}
              axis="values"
              color="#9C27B0"
              type={"spline"}
            />
            {offestedDataVisible && (
              <Series
                valueField="Slowacja_IMP_off"
                name={`Słowacja import ${selectedDates.offsetedFromDate}`}
                axis="values"
                color="#5d006d"
                type={"spline"}
              />
            )}
            <Series
              valueField="Niemcy_EXP"
              name={`Niemcy export ${selectedDates.fromDate}`}
              axis="values"
              color="#d8db00"
              dashStyle={"dash"}
              type={"spline"}
            />
            {offestedDataVisible && (
              <Series
                valueField="Niemcy_EXP_off"
                name={`Niemcy export ${selectedDates.offsetedFromDate}`}
                axis="values"
                color="#737500"
                dashStyle={"dash"}
                type={"spline"}
              />
            )}
            <Series
              valueField="Niemcy_IMP"
              name={`Niemcy import ${selectedDates.fromDate}`}
              axis="values"
              color="#d8db00"
              type={"spline"}
            />
            {offestedDataVisible && (
              <Series
                valueField="Niemcy_IMP_off"
                name={`Niemcy import ${selectedDates.offsetedFromDate}`}
                axis="values"
                color="#737500"
                type={"spline"}
              />
            )}
            <Series
              valueField="Szwecja_EXP"
              name={`Szwecja export ${selectedDates.fromDate}`}
              axis="values"
              color="#2196F3"
              dashStyle={"dash"}
              type={"spline"}
            />
            {offestedDataVisible && (
              <Series
                valueField="Szwecja_EXP_off"
                name={`Szwecja export ${selectedDates.offsetedFromDate}`}
                axis="values"
                color="#00457d"
                dashStyle={"dash"}
                type={"spline"}
              />
            )}
            <Series
              valueField="Szwecja_IMP"
              name={`Szwecja import ${selectedDates.fromDate}`}
              axis="values"
              color="#2196F3 "
              type={"spline"}
            />
            {offestedDataVisible && (
              <Series
                valueField="Szwecja_IMP_off"
                name={`Szwecja import ${selectedDates.offsetedFromDate}`}
                axis="values"
                color="#00457d"
                type={"spline"}
              />
            )}

            <Series
              valueField="Litwa_EXP"
              name={`Litwa export ${selectedDates.fromDate}`}
              axis="values"
              color="#009643"
              dashStyle={"dash"}
              type={"spline"}
            />
            {offestedDataVisible && (
              <Series
                valueField="Litwa_EXP_off"
                name={`Litwa export ${selectedDates.offsetedFromDate}`}
                axis="values"
                color="#005426"
                dashStyle={"dash"}
                type={"spline"}
              />
            )}
            <Series
              valueField="Litwa_IMP"
              name={`Litwa import ${selectedDates.fromDate}`}
              axis="values"
              color="#009643"
              type={"spline"}
            />
            {offestedDataVisible && (
              <Series
                valueField="Litwa_IMP_off"
                name={`Litwa import ${selectedDates.offsetedFromDate}`}
                axis="values"
                color="#005426"
                type={"spline"}
              />
            )}

            <ZoomAndPan argumentAxis="both" />
            <ScrollBar visible={true} />
            <Crosshair
              enabled={true}
              color="#949494"
              width={3}
              dashStyle="dash"
            >
              <Label visible={true}>
                <Font color="#fff" size={12} />
              </Label>
            </Crosshair>
            <Size height={600} />
            <Grid visible={true} />
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="bottom"
              markerRender={markerTemplate}
            />
            <Export enabled={true} />
            <AdaptiveLayout width={450} keepLabels={true} />
            <Tooltip
              border={{ color: "#d3d3d3", width: 1 }}
              shadow={{ color: "#888", opacity: 0.5 }}
              interactive={true}
              arrowLength={10}
              enabled={true}
              shared={true}
              cornerRadius={5}
              contentRender={TooltipTemplate}
            >
              <Format type="largeNumber" precision={1} />
            </Tooltip>
          </Chart>

          <DataGrid
            ref={dataGridRef}
            dataSource={transformedData}
            hoverStateEnabled={true}
            showBorders={true}
            remoteOperations={true}
            height={800}
            onExporting={(e) =>
              onExportingDataGrid(e, `${exportFileDataName}_${pagetitle}`)
            }
          >
            <HeaderFilter visible={true} search={{ enabled: true }} />
            <Scrolling mode="virtual" />
            <Export enabled={true} formats={["csv"]} />
            <Column
              dataField="Godzina"
              dataType="string"
              allowFiltering={true}
              allowSorting={true}
              width={90}
            />
            <Column caption={selectedDates.fromDate}>
              <Column caption={"Czechy"}>
                <Column
                  caption={"Export"}
                  dataField="Czechy_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption={"Import"}
                  dataField="Czechy_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
              </Column>
              <Column caption={"Słowacja"}>
                <Column
                  caption={"Export"}
                  dataField="Slowacja_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption={"Import"}
                  dataField="Slowacja_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
              </Column>
              <Column caption={"Niemcy"}>
                <Column
                  caption={"Export"}
                  dataField="Niemcy_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption={"Import"}
                  dataField="Niemcy_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
              </Column>
              <Column caption={"Szwecja"}>
                <Column
                  caption={"Export"}
                  dataField="Szwecja_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption={"Import"}
                  dataField="Szwecja_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
              </Column>
              <Column caption={"Litwa"}>
                <Column
                  caption={"Export"}
                  dataField="Litwa_EXP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                />
                <Column
                  caption={"Import"}
                  dataField="Litwa_IMP"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                ></Column>
              </Column>
            </Column>
            <Column
              caption={selectedDates.offsetedFromDate}
              visible={offestedDataVisible}
            >
              <Column caption={"Czechy"}>
                <Column
                  caption={"Export"}
                  dataField="Czechy_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
                <Column
                  caption={"Import"}
                  dataField="Czechy_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
              </Column>
              <Column caption={"Słowacja"}>
                <Column
                  caption={"Export"}
                  dataField="Slowacja_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
                <Column
                  caption={"Import"}
                  dataField="Slowacja_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
              </Column>
              <Column caption={"Niemcy"}>
                <Column
                  caption={"Export"}
                  dataField="Niemcy_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
                <Column
                  caption={"Import"}
                  dataField="Niemcy_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
              </Column>
              <Column caption={"Szwecja"}>
                <Column
                  caption={"Export"}
                  dataField="Szwecja_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
                <Column
                  caption={"Import"}
                  dataField="Szwecja_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
              </Column>
              <Column caption={"Litwa"}>
                <Column
                  caption={"Export"}
                  dataField="Litwa_EXP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                />
                <Column
                  caption={"Import"}
                  dataField="Litwa_IMP_off"
                  dataType="string"
                  allowFiltering={true}
                  allowSorting={true}
                  visible={offestedDataVisible}
                ></Column>
              </Column>
            </Column>
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlPwmRdb;
