import axios, { AxiosResponse } from "axios";
import defaultUser from "../utils/default-user";
import { api } from "./api";

import { DecodedToken } from "../types";
import jwtDecode from "jwt-decode";
import { calcCookieExpirationDate } from "../utils/calcCookieExpirationDate";
import {
  accessTokenCookieName,
  refreshTokenCookieName,
} from "../utils/constants";
import Cookies from "universal-cookie";

export async function signIn(email: string, password: string) {
  try {
    const userData = { email, password };
    const response = await api.post("auth/signin", userData);
    return {
      isOk: true,
      data: {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
      },
    };
  } catch (err: any) {
    return {
      isOk: false,
      message: err.response.data.message,
    };
  }
}

export async function signOut() {
  try {
    const response = await api.get(`auth/logout`);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getRefreshToken(refreshTokenCookie: string) {
  try {
    console.log(refreshTokenCookie, "refreshTokenCookie");
    const cookies = new Cookies();
    const { sub } = jwtDecode<DecodedToken>(refreshTokenCookie);
    const response = await api.get("auth/refresh", {
      params: {
        sub: sub,
        refreshToken: refreshTokenCookie,
      },
    });
    const { accessToken, refreshToken } = response.data;
    const decodedAccessToken = jwtDecode<DecodedToken>(accessToken!);
    const decodedRefreshToken = jwtDecode<DecodedToken>(refreshToken!);
    cookies.set(accessTokenCookieName, accessToken, {
      path: "/",
      maxAge: calcCookieExpirationDate(decodedAccessToken.exp),
    });
    cookies.set(refreshTokenCookieName, refreshToken, {
      path: "/",
      maxAge: calcCookieExpirationDate(decodedRefreshToken.exp),
    });
    return { accessToken, refreshToken };
  } catch (err) {
    throw new Error("Wystąpił błąd w trakcie odświeżania tokena");
  }
}

export async function getUser(token: string) {
  try {
    const response = await api.get("whoAmI", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Wystąpił błąd w trakcie tworzenia konta",
    };
  }
}

export async function changePassword(
  password: string,
  newPassword: string,
  userId: string
) {
  const cookies = new Cookies();
  const accessTokenCookie = cookies.get(accessTokenCookieName);
  const decodedAccessToken = jwtDecode<DecodedToken>(accessTokenCookie);
  console.log(decodedAccessToken, "decodedAccessToken");
  try {
    // Send request
    // console.log(email, recoveryCode);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Wystąpił błąd w trakcie zmiany hasła",
    };
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Wystąpił błąd w trakcie resetowania hasła",
    };
  }
}
