import React, { useEffect, useState } from "react";
import "devextreme/data/odata/store";

import { LoadPanel } from "devextreme-react";

import { api } from "../../api/api";

import ResponsiveBox, {
  Row,
  Col,
  Item as ResponsiveItem,
  Location,
} from "devextreme-react/responsive-box";

import { TransformedStockData, ReturnDateObject } from "../../types";

import notify from "devextreme/ui/notify";
import { convertLongDateStringToShortFormat } from "../../utils/convertLongDateStringToShortFormat";
import DateSelector from "../../components/date-selector/DateSelector";
import { differenceInDays } from "../../utils/calcDiffBetweenDates";
import moment from "moment";
import { mergeByProperty } from "../../utils/mergeByProperty";
import { listName } from "./PkdValuesList";
import BpkdChart from "./PkdChart";
import BpkdDataGrid from "./PkdDataGrid";
const Pkd = () => {
  const position = { of: "#bpkd-content" };
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [offestedDataVisible, setOffestedDataVisible] = useState(false);
  const [exportFileDataName, setExportFileDataName] = useState("");
  const [selectedDates, setSelectedDates] = useState<ReturnDateObject>({
    fromDate: "",
    toDate: "",
    offsetedFromDate: "",
    offsetedToDate: "",
  });
  const bpkdUrlEndpoint = "pkd";
  const [transformedData, setTransformedData] = useState<
    TransformedStockData[]
  >([]);

  const transformPkdData = (data: any, daysOffset: number) => {
    const values = [
      "Data",
      "Godzina",
      "Krajowe_zapotrzebowanie_na_moc",
      "Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa",
      "Generacja_zrodel_wiatrowych",
      "Generacja_zrodel_fotowoltaicznych",
      "Rezerwa_mocy_ponad_zapotrzebowanie",
      "Rezerwa_mocy_ponizej_zapotrzebowania",
    ];
    const transformDateFormat = (
      date: Date,
      daysOffset: number,
      hour: number
    ) => {
      const newDate = moment(date).add(daysOffset, "days").toString();
      // .toString();
      return newDate;
    };
    const newArray = data.map((obj: any) =>
      values.reduce((acc, key) => {
        const newKey = `${key}`;
        if (key === "Data") {
          return {
            ...acc,
            [`${newKey}`]: transformDateFormat(
              obj[key],
              daysOffset,
              obj["Godzina"]
            ),
            [`${newKey}_original`]: transformDateFormat(
              obj[key],
              0,
              obj["Godzina"]
            ),
          };
        } else {
          return { ...acc, [`${newKey}`]: obj[key] };
        }
      }, {})
    );

    const newArraychangedName = newArray.map((item: any) =>
      daysOffset
        ? Object.fromEntries(
            Object.entries(item).map(([key, value]) =>
              key === "Data" || key === "Godzina"
                ? [key, value]
                : ["offset_" + key, value]
            )
          )
        : Object.fromEntries(
            Object.entries(item).map(([key, value]) =>
              key === "Data" || key === "Godzina"
                ? [key, value]
                : ["orginal_" + key, value]
            )
          )
    );

    return newArraychangedName;
  };

  const getData = async (
    fromDate: string,
    toDate: string,
    daysOffset?: number
  ): Promise<Array<any> | undefined> => {
    setIsLoadingVisible(true);

    const filter = {
      // limit: 1,
      // order: "string",
      where: {
        data_cet: {
          $gte: fromDate,
          $lt: toDate,
        },
      },
    };
    try {
      setTransformedData([]);
      const { data } = await api.get<any[]>(bpkdUrlEndpoint, {
        params: { filter },
      });

      if (data?.length) {
        console.log(data);
        setIsLoadingVisible(false);
        const transformedArray = data.map((obj) => {
          const pkdData = JSON.parse(JSON.stringify(obj.dane));
          const pkdData_transformed = transformPkdData(
            pkdData,
            daysOffset || 0
          );
          return [...pkdData_transformed];
        });
        const flatedArray = transformedArray.flat();
        return flatedArray;
      } else {
        setIsLoadingVisible(false);
        notify(
          "Brak danych w wybranym okresie czasu " +
            convertLongDateStringToShortFormat(fromDate),
          "warning",
          2000
        );

        return undefined;
      }
    } catch (error) {
      setIsLoadingVisible(false);
      notify("Nie udało się pobrać danych", "error", 2000);
      return undefined;
    }
  };

  const getStockData = async (
    selectedDates: ReturnDateObject
  ): Promise<void> => {
    setSelectedDates(selectedDates);
    setOffestedDataVisible(false);
    setExportFileDataName(
      convertLongDateStringToShortFormat(selectedDates.fromDate)
    );
    if (selectedDates.offsetedFromDate && selectedDates.offsetedToDate) {
      const dateDiff = differenceInDays(
        moment(selectedDates.fromDate).toDate(),
        moment(selectedDates.offsetedFromDate).toDate()
      );
      Promise.all([
        getData(selectedDates.fromDate, selectedDates.toDate),
        getData(
          selectedDates.offsetedFromDate,
          selectedDates.offsetedToDate,
          dateDiff
        ),
      ])
        .then(([data, offsetedData]) => {
          if (data !== undefined && offsetedData !== undefined) {
            setOffestedDataVisible(true);
            const arr = mergeByProperty(data, offsetedData, "Data");
            setTransformedData(arr as any[]);
          }
          if (data !== undefined && offsetedData === undefined) {
            setTransformedData(data as any[]);
          }
        })
        .catch((error) => console.error(error));
    } else {
      const data = await getData(selectedDates.fromDate, selectedDates.toDate);
      setTransformedData(data as any[]);
    }
  };

  function screen(width: number) {
    return width < 1400 ? "sm" : "lg";
  }

  const responsiveBoxAttributes = {
    class: "bpkd_reponsive_box",
  };
  return (
    <React.Fragment>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={isLoadingVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <h2 className={"content-block"}>Plan Koordynacyjny Dobowy</h2>
      <DateSelector
        defaultOffset={2}
        onClick={(dateObj) => getStockData(dateObj)}
      />
      <div id={"bpkd-content"} className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <ResponsiveBox
            elementAttr={responsiveBoxAttributes}
            singleColumnScreen="sm"
            screenByWidth={screen}
          >
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>

            <Col ratio={1}></Col>
            <Col ratio={1}></Col>

            <ResponsiveItem>
              <Location row={0} col={0} screen="lg"></Location>
              <Location row={0} col={0} screen="sm"></Location>
              <div className="content item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Generacja źródeł wiatrowych"}
                  variableName={"Generacja_zrodel_wiatrowych"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Generacja_zrodel_wiatrowych"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={0} col={1} screen="lg"></Location>
              <Location row={1} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Generacja źródeł fotowoltaicznych"}
                  variableName={"Generacja_zrodel_fotowoltaicznych"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Generacja_zrodel_fotowoltaicznych"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={1} col={0} screen="lg"></Location>
              <Location row={2} col={0} screen="sm"></Location>
              <div className="content item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Rezerwa mocy ponad zapotrzebowania"}
                  variableName={"Rezerwa_mocy_ponad_zapotrzebowanie"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Rezerwa_mocy_ponad_zapotrzebowanie"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={1} col={1} screen="lg"></Location>
              <Location row={3} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Rezerwa mocy poniżej zapotrzebowania"}
                  variableName={"Rezerwa_mocy_ponizej_zapotrzebowania"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Rezerwa_mocy_ponizej_zapotrzebowania"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={2} col={0} screen="lg"></Location>
              <Location row={4} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Krajowe zapotrzebowanie na moc"}
                  variableName={"Krajowe_zapotrzebowanie_na_moc"}
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={"Krajowe_zapotrzebowanie_na_moc"}
                />
              </div>
            </ResponsiveItem>
            <ResponsiveItem>
              <Location row={2} col={1} screen="lg"></Location>
              <Location row={5} col={0} screen="sm"></Location>
              <div className="left-side-bar item">
                <BpkdChart
                  transformedData={transformedData}
                  selectedDates={selectedDates}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  title={"Sumaryczne generacja JG aktywnych"}
                  variableName={
                    "Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa"
                  }
                />
                <BpkdDataGrid
                  transformedData={transformedData}
                  offestedDataVisible={offestedDataVisible}
                  exportFileDataName={exportFileDataName}
                  variableName={
                    "Sumaryczna_generacja_JG_aktywnych:_JGWa,_JGFWa,_JGMa_i_JGPVa"
                  }
                />
              </div>
            </ResponsiveItem>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pkd;
