import { SimpleItem, ButtonItem } from "devextreme-react/form";
import { useEffect, useState } from "react";
import { Form } from "devextreme-react";
import "./DateTimeSelector.css";
import { DateSelectorProps } from "../../types";

import { setSelectedTimeDates } from "../../utils/DateSelectors";
import moment from "moment";
import { colCountByScreen } from "../../utils/constants";

const DateSelector = ({ onClick }: DateSelectorProps) => {
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [selectedTime, setSelectedTime] = useState("00");
  const [offsetDate, setOffsetDate] = useState(selectedDate);

  const handleFromChange = (value: Date) => {
    setSelectedDate(moment(value).toDate());
    setOffsetDate(moment(value).toDate());
  };
  const handleTimeChange = (value: string) => setSelectedTime(value);
  const handleOffsetChange = (value: Date) => {
    setOffsetDate(moment(value).toDate());
  };
  const rangeSelector = {
    FromDate: selectedDate,
    Time: selectedTime,
    OffsetDate: offsetDate,
  };
  const fromDateOptions = {
    max: moment().toDate(),
  };
  const offsetDateOptions = {
    max: selectedDate,
  };
  useEffect(() => {
    const data = setSelectedTimeDates({
      selectedFromDate: selectedDate,
      selectedToDate: offsetDate,
      time: selectedTime,
    });
    onClick(data);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = setSelectedTimeDates({
      selectedFromDate: selectedDate,
      selectedToDate: offsetDate,
      time: selectedTime,
    });
    onClick(data);
  };
  const createTimesArray = (): string[] =>
    Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, "0")}`);

  const timesArray = createTimesArray();
  return (
    <>
      <div className={"content-block dx-card responsive-paddings"}>
        <form onSubmit={handleSubmit}>
          <Form
            className="flexEnd"
            style={{ alignItems: "flex-end !important" }}
            formData={rangeSelector}
            id={"form"}
            onFieldDataChanged={(e) => {
              e.dataField === "FromDate" && handleFromChange(e.value);
              e.dataField === "Time" && handleTimeChange(e.value);
              e.dataField === "OffsetDate" && handleOffsetChange(e.value);
            }}
            colCountByScreen={colCountByScreen}
          >
            <SimpleItem
              dataField="FromDate"
              label={{ text: "Od" }}
              editorOptions={fromDateOptions}
            />
            <SimpleItem
              dataField="Time"
              editorType="dxSelectBox"
              label={{ text: "Godzina" }}
              editorOptions={{ items: timesArray }}
            />
            <SimpleItem
              dataField="OffsetDate"
              label={{ text: "Przeusnięcie daty" }}
              editorOptions={offsetDateOptions}
            />
            <ButtonItem
              horizontalAlignment={"left"}
              buttonOptions={{
                text: "Wybierz",
                type: "success",
                useSubmitBehavior: true,
              }}
            />
          </Form>
        </form>
      </div>
    </>
  );
};

export default DateSelector;
