import React, { useEffect, useState } from "react";
import "./profile.scss";
import Form, { ButtonItem, Item, SimpleItem } from "devextreme-react/form";
import Button from "devextreme-react/button";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import notify from "devextreme/ui/notify";
import { api } from "../../api/api";
import { useAuth } from "../../contexts/auth";
import CustomPasswordTextBox from "../../components/custom-password-box/CustomPasswordBox";
import { ChangePasswordForm } from "../../components";

export default function Profile() {
  const [userData, setUserData] = useState({
    Name: "",
    email: "",
    username: "",
  });
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { user, loading } = useAuth();

  const getUserData = async () => {
    const userData = await api.get(`users/whoami/${user?.sub}`);
    console.log(user);
    setUserData(userData.data);
    console.log(userData, user?.sub);
  };

  const changePassword = async () => {
    if (passwords.newPassword !== passwords.confirmPassword) {
      notify("Nowe hasła nie są identyczne", "error", 3000);
    } else {
      try {
        await api.put(`users/${user?.sub}/password`, {
          old_password: passwords.oldPassword,
          new_password: passwords.newPassword,
        });
        setPasswords({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        notify("Hasło zostało zmienione", "success", 3000);
      } catch (error) {
        console.error(error);
        notify("Błąd podczas zmiany hasła", "error", 3000);
      }
    }
  }; // Funkcja do wysłania żądania zmiany hasła

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Profil użytkownika</h2>

      <div className={"content-block dx-card responsive-paddings"}>
        {userData.email && (
          <>
            <Form
              id={"form"}
              defaultFormData={userData}
              readOnly={true}
              labelLocation={"top"}
              colCountByScreen={colCountByScreen}
              items={[
                {
                  dataField: "name",
                  label: { text: "Imię i Nazwisko" },
                },
                {
                  dataField: "email",
                  label: { text: "Email" },
                },
                {
                  dataField: "username",
                  label: { text: "Nazwa użytkownika" },
                },
              ]}
            />
          </>
        )}
      </div>

      <h2 className={"content-block"}>Zmiana hasła</h2>
      <div className={"content-block dx-card responsive-paddings"}>
        <ChangePasswordForm userId={user?.sub as string} />
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};
