import { SimpleItem, ButtonItem, Item } from "devextreme-react/form";
import { useEffect, useState } from "react";
import { DateBox, DateRangeBox, Form } from "devextreme-react";
import "./DateSelector.css";
import { DateSelectorProps, StockDataChildProps } from "../../types";
import {
  colCountByScreen,
  defaultDateOffsetInDays,
} from "../../utils/constants";
import { setSelectedRangeDates } from "../../utils/DateSelectors";
import moment from "moment";
import React from "react";
const DateSelector = ({
  onClick,
  defaultOffset,
  maxRange,
}: DateSelectorProps) => {
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [offsetDate, setOffsetDate] = useState(moment().toDate());
  const [selectedToDate, setSelectedToDate] = useState(
    moment(selectedDate)
      .add(defaultOffset || maxRange || defaultDateOffsetInDays, "days")
      .toDate()
  );

  const [toDate, setToDate] = useState(
    moment(selectedDate)
      .add(maxRange || defaultOffset || defaultDateOffsetInDays, "days")
      .toDate()
  );

  const handleFromChange = (value: Date) => {
    setSelectedDate(moment(value).toDate());
    setSelectedToDate(
      moment(value)
        .add(maxRange || defaultDateOffsetInDays, "days")
        .toDate()
    );
    setToDate(
      moment(value)
        .add(maxRange || defaultDateOffsetInDays, "days")
        .toDate()
    );
    setOffsetDate(moment(value).toDate());
  };
  const handleToChange = (value: Date) => {
    setSelectedToDate(value);
  };

  const rangeSelector = {
    FromDate: selectedDate,
    ToDate: selectedToDate,
    OffsetDate: offsetDate,
  };
  const fromDateOptions = {
    max: moment().toDate(),
  };
  const toDateOptions = {
    max: toDate,
    min: selectedDate,
  };
  const offsetDateOptions = {
    max: selectedDate,
  };
  const handleOffsetChange = (value: Date) => {
    setOffsetDate(moment(value).toDate());
  };

  const getSelectedRangeDates = () => {
    return setSelectedRangeDates({
      selectedFromDate: selectedDate,
      selectedToDate: selectedToDate,
      selectedOffsetDate: offsetDate,
    });
  };
  useEffect(() => {
    const data = getSelectedRangeDates();
    onClick(data);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = getSelectedRangeDates();
    onClick(data);
  };

  return (
    <>
      <div className={"content-block dx-card responsive-paddings"}>
        <form onSubmit={handleSubmit}>
          <Form
            className="flexEnd"
            style={{ alignItems: "flex-end !important" }}
            formData={rangeSelector}
            id={"form"}
            onFieldDataChanged={(e) => {
              e.dataField === "FromDate" && handleFromChange(e.value);
              e.dataField === "ToDate" && handleToChange(e.value);
              e.dataField === "OffsetDate" && handleOffsetChange(e.value);
            }}
            colCountByScreen={colCountByScreen}
          >
            <SimpleItem
              dataField="FromDate"
              label={{ text: "Od" }}
              editorOptions={fromDateOptions}
            />

            <SimpleItem
              dataField="ToDate"
              label={{ text: "Do" }}
              editorOptions={toDateOptions}
            />
            <SimpleItem
              dataField="OffsetDate"
              label={{ text: "Przeusnięcie daty" }}
              editorOptions={offsetDateOptions}
            />
            <ButtonItem
              horizontalAlignment={"left"}
              buttonOptions={{
                text: "Wybierz",
                type: "success",
                useSubmitBehavior: true,
              }}
            />
          </Form>
        </form>
      </div>
    </>
  );
};

export default DateSelector;
